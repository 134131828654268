import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { NgSelectComponent } from "@ng-select/ng-select";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { customInputNumber } from "src/app/core/common/utils";
import { CancellationCost } from "src/app/core/models/domain/CancellationCost";
import { ScheduleDetails } from "../schedule.details";
import { ResolutionTypes } from "../resolution.types";
import { ConfigurationService } from "src/app/core/svc/configuration-service";

const environment = new ConfigurationService().getConfig();
const campaignConfig = environment.campaignConfig;

@Component({
  selector: "app-cancel-booking-dialog",
  templateUrl: "./cancel-booking-dialog.component.html",
  styleUrls: ["./cancel-booking-dialog.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CancelBookingDialogComponent implements OnInit {
  customInputNumber = customInputNumber;
  form: UntypedFormGroup;
  cancellationFee: number;
  region = environment.region;
  currencySymbol: string;
  resolutionTypes = [
    ResolutionTypes.DeferWithoutPayment,
    ResolutionTypes.DeferWithPartPayment,
    ResolutionTypes.DeferWithFullPayment,
    ResolutionTypes.CancelWithoutFee,
    ResolutionTypes.CancelWithFee,
  ];
  @ViewChild("resolutionNgSelect", { static: true }) resolutionNgSelect: NgSelectComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ScheduleDetails,
    public dialogRef: MatDialogRef<CancelBookingDialogComponent>,
    private fb: UntypedFormBuilder
  ) {
    this.data.scheduleStartDate = this.data.scheduleStartDate.split(",")[0];
    this.data.scheduleEndDate = this.data.scheduleEndDate.split(",")[0];
    this.form = this.fb.group({
      resolution: this.fb.control(null, Validators.required),
      amount: this.fb.control(null, Validators.required),
    });
  }
  ngOnInit(): void {
    this.currencySymbol = campaignConfig.currencySymbol;
  }

  onResolutionTypeChange(type) {
    switch (type) {
      case ResolutionTypes.CancelWithFee:
        this.form.patchValue({ amount: 0 });
        this.form.get("amount").enable();
        break;
      case ResolutionTypes.DeferWithoutPayment:
        this.form.patchValue({ amount: 0 });
        this.form.get("amount").disable();
        break;
      case ResolutionTypes.DeferWithPartPayment:
        this.form.patchValue({ amount: this.data.sellCost.value.toFixed(2) });
        this.form.get("amount").enable();
        break;
      case ResolutionTypes.DeferWithFullPayment:
        this.form.patchValue({ amount: this.data.sellCost.value.toFixed(2) });
        this.form.get("amount").disable();
        break;
      case ResolutionTypes.CancelWithoutFee:
        this.form.patchValue({ amount: 0 });
        this.form.get("amount").disable();
        break;
    }
  }

  close() {
    this.dialogRef.close();
  }

  onSubmit() {
    this.form.get("amount").enable();
    const cancellationCost: CancellationCost = {
      resolutionType: this.form.value.resolution,
      cost: {
        currency: this.data.sellCost.currency,
        value: this.form.value.amount,
      },
    };
    this.dialogRef.close(cancellationCost);
  }
}
