<app-dialog
  class="confirmation-dialog"
  accentColor="primary"
  icon="info"
  title="Attention"
  [message]="message"
  [secondaryMessage]="secondaryMessage"
  primaryActionLabel="Ok"
  (primaryClick)="proceed(true)"
  [secondaryActionLabel]="secondaryActionLabel"
  (secondaryClick)="proceed(false)"
>
</app-dialog>
