<mat-expansion-panel
  [(expanded)]="isExpanded"
  class="plato-expansion-panel elevated"
  [ngClass]="{ elevated: elevated }"
  [disabled]="true"
>
  <mat-expansion-panel-header (click)="toggleExpansionPanel()">
    <mat-panel-title>
      <i [ngClass]="isExpanded ? 'down-arrow' : 'right-arrow'"></i>
      {{ title }}
    </mat-panel-title>
  </mat-expansion-panel-header>
  <ng-content></ng-content>
</mat-expansion-panel>
