import { Component, OnInit, ViewEncapsulation, Input, OnChanges, SimpleChanges } from "@angular/core";

@Component({
  selector: "app-notification-icon",
  templateUrl: "./notification-icon.component.html",
  styleUrls: ["./notification-icon.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class NotificationIconComponent implements OnChanges {
  @Input() unreadNotifications: number;
  @Input() notificationIcon: string;

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.unreadNotifications &&
      changes.unreadNotifications.currentValue !== changes.unreadNotifications.previousValue
    ) {
      this.unreadNotifications = changes.unreadNotifications.currentValue;
    }
  }
}
