import * as i0 from '@angular/core';
import { Injectable, Inject, Component, NgModule } from '@angular/core';
import * as i1 from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EventSourcePolyfill } from 'event-source-polyfill';
var FitlerType;
(function (FitlerType) {
  FitlerType["TARGET"] = "TARGET";
  FitlerType["FROMDATE"] = "FROMDATE";
  FitlerType["TILLDATE"] = "TILLDATE";
  FitlerType["READSTATUS"] = "READSTATUS";
  FitlerType["NOTIFICATIONTYPE"] = "NOTIFICATIONTYPE";
  FitlerType["ADDITIONALINFO"] = "ADDITIONALINFO";
})(FitlerType || (FitlerType = {}));
var TargetType;
(function (TargetType) {
  TargetType["PLATO_USER_ID"] = "PLATO_USER_ID";
  TargetType["MO_PORTAL_USER_ID"] = "MO_PORTAL_USER_ID";
})(TargetType || (TargetType = {}));
var ReadStatus;
(function (ReadStatus) {
  ReadStatus["READ"] = "READ";
  ReadStatus["UNREAD"] = "UNREAD";
})(ReadStatus || (ReadStatus = {}));
var InAppType;
(function (InAppType) {
  InAppType["CAMPAIGN_UPDATE"] = "CAMPAIGN_UPDATE";
  InAppType["CHAT"] = "CHAT_UPDATE";
})(InAppType || (InAppType = {}));
class AlertAndNotificationService {
  constructor(http, environment) {
    this.http = http;
    this.environment = environment;
  }
  /**
   * Genrates url with params
   * @param url The base url
   * @param params params to be appended to the url
   */
  addUrlParams(url, params) {
    url += '?';
    params.forEach(param => {
      const key = Object.keys(param)[0];
      url += `${key}=${encodeURIComponent(param[key])}&`;
    });
    return url;
  }
  /**
   * Generates array of param objects from filters
   * @param filters Array of filters to be converted into url param objects of form { param: paramValue }
   */
  getParamsFromFilters(filters) {
    let targetType = '';
    const params = filters.map(filter => {
      if (filter.kind === FitlerType.TARGET) {
        targetType = filter.type;
        return filter.values.map(value => ({
          ['target.values']: value
        }));
      }
      if (filter.kind === FitlerType.FROMDATE) {
        return [{
          ['fromDate']: filter.fromDate
        }];
      }
      if (filter.kind === FitlerType.TILLDATE) {
        return [{
          ['tillDate']: filter.tillDate
        }];
      }
      if (filter.kind === FitlerType.READSTATUS) {
        return [{
          ['status']: filter.status
        }];
      }
      if (filter.kind === FitlerType.NOTIFICATIONTYPE) {
        return [{
          ['type.type']: filter.type
        }, {
          ['type.negate']: filter.negate
        }];
      }
      // eslint-disable-next-line max-len
      if (filter.kind === FitlerType.ADDITIONALINFO) {
        return [{
          ['additionalInfo.key']: filter.key
        }, {
          ['additionalInfo.value']: filter.value
        }, {
          ['additionalInfo.negate']: filter.negate
        }];
      }
    }).reduce((previous, next) => [...previous, ...next]);
    params.push({
      ['target.type']: targetType
    });
    return params;
  }
  /**
   * Gets notifications from the server using SSE
   * @param filters Array of filters to apply before sending notifications
   */
  getNotifications(jwt, filters) {
    const params = this.getParamsFromFilters(filters);
    params.push({
      jwt
    });
    const urlWithParams = this.addUrlParams(`${this.environment.url}/ans/inapp/push`, params);
    return new Observable(observer => {
      const eventSource = new EventSourcePolyfill(urlWithParams, {
        headers: {
          Authorization: 'Bearer ' + jwt
        }
      });
      eventSource.onmessage = message => {
        if (message.data && message.data !== 'dummy') {
          observer.next(JSON.parse(message.data));
        }
      };
      return () => eventSource.close();
    });
  }
  /**
   * Gets all notifications based on the given filters
   * @param filters Array of filters to apply before sending notifications
   */
  getAllNotifications(filters, pageNumber, pageSize = 40) {
    const params = [...this.getParamsFromFilters(filters), ...[{
      page: pageNumber
    }, {
      size: pageSize
    }]];
    // eslint-disable-next-line max-len
    return this.http.get(this.addUrlParams(`${this.environment.url}/ans/inapp/paginated`, params), {
      headers: new HttpHeaders({
        ignoreProgressBar: ''
      })
    });
  }
  /**
   * Gets all un-paged notifications based on the given filters
   * @param filters Array of filters to apply before sending notifications
   */
  getAllNotificationsUnpaged(filters) {
    const params = this.getParamsFromFilters(filters);
    // eslint-disable-next-line max-len
    return this.http.get(this.addUrlParams(`${this.environment.url}/ans/inapp`, params), {
      headers: new HttpHeaders({
        ignoreProgressBar: ''
      })
    });
  }
  /**
   * Gets notifications count based on the given filters
   * @param filters Array of filters to apply before sending notifications
   */
  getNotificationsCount(filters) {
    const params = this.getParamsFromFilters(filters);
    // eslint-disable-next-line max-len
    return this.http.get(this.addUrlParams(`${this.environment.url}/ans/inapp/count`, params), {
      headers: new HttpHeaders({
        ignoreProgressBar: ''
      })
    });
  }
  static {
    this.ɵfac = function AlertAndNotificationService_Factory(t) {
      return new (t || AlertAndNotificationService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject('environment'));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: AlertAndNotificationService,
      factory: AlertAndNotificationService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AlertAndNotificationService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: ['environment']
    }]
  }], null);
})();
class AlertAndNotificationComponent {
  constructor() {}
  ngOnInit() {}
  static {
    this.ɵfac = function AlertAndNotificationComponent_Factory(t) {
      return new (t || AlertAndNotificationComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: AlertAndNotificationComponent,
      selectors: [["ng-alert-and-notification"]],
      decls: 2,
      vars: 0,
      template: function AlertAndNotificationComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "p");
          i0.ɵɵtext(1, " alert-and-notification works! ");
          i0.ɵɵelementEnd();
        }
      },
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AlertAndNotificationComponent, [{
    type: Component,
    args: [{
      selector: 'ng-alert-and-notification',
      template: `
    <p>
      alert-and-notification works!
    </p>
  `
    }]
  }], () => [], null);
})();
class AlertAndNotificationModule {
  static forRoot(environment) {
    return {
      ngModule: AlertAndNotificationModule,
      providers: [{
        provide: 'environment',
        useValue: environment
      }]
    };
  }
  static {
    this.ɵfac = function AlertAndNotificationModule_Factory(t) {
      return new (t || AlertAndNotificationModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: AlertAndNotificationModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AlertAndNotificationModule, [{
    type: NgModule,
    args: [{
      imports: [],
      declarations: [AlertAndNotificationComponent],
      exports: [AlertAndNotificationComponent]
    }]
  }], null, null);
})();

/*
 * Public API Surface of alert-and-notification
 */

/**
 * Generated bundle index. Do not edit.
 */

export { AlertAndNotificationComponent, AlertAndNotificationModule, AlertAndNotificationService, FitlerType, InAppType, ReadStatus, TargetType };
