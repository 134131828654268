import { AATColumnTemplate } from "../../app/home/audience-analysis-tool/compare-audiences/aat-column-template";
import { AATColumn } from "src/app/home/audience-analysis-tool/compare-audiences/aat-columns";

export const columns = {
  frame_id: "frameId",
  panel_name: "panelName",
  panel_code: "panelCode",
  media_owner: "mediaOwner.name",
  environment: "environment.name",
  illumination_type: "format.illuminationType",
  orientation: "format.orientation",
  formatGroup: "format.formatGroup",
  format: "format.formatName",
  on_route: "format.onRoute",
  has_motion: "format.hasMotion",
  face_count: "format.faceCount",
  face_type: "format.faceType",
  coordinates: "geography.location.coordinates",
  tv_region: "geography.tvRegion",
  town: "geography.town",
  conurbation: "geography.conurbation",
  country: "geography.country",
  postcode: "geography.postcode",
  postcode_sector: "geography.postcodeSector",
  address: "geography.address",
  street_name: "geography.streetName",
  locality: "geography.locality",
  administrative_area: "geography.localAuthorityDistrict.name",
  height: "format.height",
  width: "format.width",
  surface_area: "format.surfaceArea",
  site_code: "geography.siteCode",
  impacts: "frameStats.averageImpacts",
  availability: "availability.state",
  nearest_poi: "nearestPOIDetail.poiName",
  poi_address: "nearestPOIDetail.poiAddress",
  poi_distance_from_frame: "nearestPOIDetail.distance.value",
  poi_distance_range_bucket: "nearestPOIDetail.distanceBucketRange",
  price: "price",
  default_sot_percentage: "availability.defaultSOTPercentage",
  is_blacklisted_frame: "isBlacklistedFrame",
  audience_index: "audienceIndex",
  audience_on_target_percentage: "audienceOnTargetPercentage",
  source_system_frame_id: "sourceSystemFrameID",
};

// UK columns for Search Grid

export const searchGridColumns = [
  columns.format,
  columns.formatGroup,
  columns.frame_id,
  columns.panel_code,
  columns.panel_name,
  columns.media_owner,
  columns.environment,
  columns.impacts,
  columns.audience_on_target_percentage,
  columns.audience_index,
  columns.illumination_type,
  columns.orientation,
  columns.on_route,
  columns.has_motion,
  columns.face_count,
  columns.face_type,
  columns.tv_region,
  columns.town,
  columns.conurbation,
  columns.country,
  columns.postcode,
  columns.postcode_sector,
  columns.coordinates,
  columns.address,
  columns.street_name,
  columns.locality,
  columns.administrative_area,
  columns.height,
  columns.width,
  columns.surface_area,
  columns.site_code,
  columns.nearest_poi,
  columns.poi_address,
  columns.poi_distance_range_bucket,
  columns.poi_distance_from_frame,
  columns.price,
  columns.availability,
  columns.default_sot_percentage,
  columns.is_blacklisted_frame,
  columns.source_system_frame_id,
];

export const columnsWidth = {
  [columns.format]: 120,
  [columns.formatGroup]: 120,
  [columns.frame_id]: 102,
  [columns.panel_code]: 90,
  [columns.panel_name]: 140,
  [columns.media_owner]: 118,
  [columns.environment]: 140,
  [columns.illumination_type]: 120,
  [columns.orientation]: 95,
  [columns.on_route]: 78,
  [columns.has_motion]: 90,
  [columns.availability]: 120,
  [columns.face_count]: 88,
  [columns.face_type]: 88,
  [columns.town]: 115,
  [columns.conurbation]: 120,
  [columns.country]: 120,
  [columns.postcode]: 82,
  [columns.postcode_sector]: 123,
  [columns.coordinates]: 132,
  [columns.address]: 120,
  [columns.street_name]: 115,
  [columns.locality]: 115,
  [columns.administrative_area]: 140,
  [columns.height]: 65,
  [columns.width]: 65,
  [columns.audience_index]: 120,
  [columns.audience_on_target_percentage]: 160,
  [columns.surface_area]: 100,
  [columns.site_code]: 80,
  [columns.impacts]: 80,
  [columns.nearest_poi]: 100,
  [columns.poi_address]: 140,
  [columns.poi_distance_range_bucket]: 135,
  [columns.poi_distance_from_frame]: 135,
  [columns.price]: 80,
  [columns.tv_region]: 115,
  [columns.default_sot_percentage]: 80,
  [columns.is_blacklisted_frame]: 80,
  [columns.source_system_frame_id]: 60,
};

export const columnsSortable = {
  [columns.impacts]: false,
};

export const columnsDraggable = {
  [columns.impacts]: false,
};

export const mandatoryColumns = [columns.price, columns.availability];

export const features = {
  supplierContractNumber: "supplierContractNumber",
  contracting: "contracting",
  tradingModeAndAvails: "tradingModeAndAvails",
  rfp: "rfp",
};

export const featuresEnabled = {
  [features.supplierContractNumber]: true,
  [features.contracting]: false,
  [features.tradingModeAndAvails]: true,
  [features.rfp]: true,
};

export const userSelectedColumns = [
  columns.format,
  columns.frame_id,
  columns.panel_name,
  columns.media_owner,
  columns.environment,
  columns.orientation,
  columns.has_motion,
  columns.tv_region,
  columns.town,
  columns.postcode,
  columns.postcode_sector,
  columns.address,
  columns.street_name,
  columns.locality,
];

export const pinnedRightColumns = [columns.availability];

export const aatColumns = [
  { columnType: AATColumn.audienceName, field: "audienceName", header: "Audience", width: 150 } as AATColumnTemplate,
  { columnType: AATColumn.totalFrames, field: "totalFrames", header: "Total Frames", width: 85 } as AATColumnTemplate,
  { columnType: AATColumn.reach, field: "reach", header: "Reach ('000)", width: 85 } as AATColumnTemplate,
  {
    columnType: AATColumn.averageFrequency,
    field: "average_frequency",
    header: "Frequency",
    width: 85,
  } as AATColumnTemplate,
  { columnType: AATColumn.impacts, field: "impacts", header: "Impacts ('000)", width: 85 } as AATColumnTemplate,
  { columnType: AATColumn.cover, field: "cover", header: "Cover (%)", width: 85 } as AATColumnTemplate,
  {
    columnType: AATColumn.grossRatingPoints,
    field: "gross_rating_points",
    header: "GRP",
    width: 50,
  } as AATColumnTemplate,
  { columnType: AATColumn.ragStatus, field: "ragStatus", header: "RAG", width: 45 } as AATColumnTemplate,
  { columnType: AATColumn.audienceId, field: "audienceId", header: "", width: 75 } as AATColumnTemplate,
];
