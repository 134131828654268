<mat-form-field>
  <mat-select
    id="select-region"
    name="region"
    [(ngModel)]="selectedRegion"
    [(value)]="selectedRegion"
    (selectionChange)="changeRegion(selectedRegion)"
  >
    <mat-option [value]="region" *ngFor="let region of regions">
      {{ region }}
    </mat-option>
  </mat-select>
</mat-form-field>
