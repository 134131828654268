import { AATColumnTemplate } from "src/app/home/audience-analysis-tool/compare-audiences/aat-column-template";
import { AATColumn } from "src/app/home/audience-analysis-tool/compare-audiences/aat-columns";

export const columns = {
  frame_id: "frameId",
  spot_id: "spotReferences.0.spotId",
  image: "specifications.imageUrls.0",
  mo_frame_reference: "mediaOwnerReferenceIdForFrame",
  source_system: "sourceSystem",
  media_owner: "mediaOwner.name",
  environment: "environment.name",
  illumination_type: "format.illuminationType",
  orientation: "format.orientation",
  height: "format.height",
  width: "format.width",
  size: "format.size",
  formatGroup: "format.formatGroup",
  format: "format.formatName",
  has_motion: "format.hasMotion",
  coordinates: "geography.location.coordinates",
  latitude: "geography.location.coordinates.0",
  longitude: "geography.location.coordinates.1",
  tv_region: "geography.tvRegion",
  cbsa: "geography.cbsaName",
  city: "geography.city",
  town: "geography.town",
  postcode: "geography.postcode",
  address: "geography.address",
  surface_area: "format.surfaceArea",
  nearest_poi: "nearestPOIDetail.poiName",
  poi_address: "nearestPOIDetail.poiAddress",
  poi_distance_from_frame: "nearestPOIDetail.distance.value",
  poi_distance_range_bucket: "nearestPOIDetail.distanceBucketRange",
  county: "geography.county",
  country: "geography.countryCode",
  state: "geography.state",
  direction: "geography.frameDirection.code",
  price: "price",
  number_of_associated_POIs: "prohibition.numberOfAssociatedPOIs",
  prohibition_status: "prohibition.status",
  default_sot_percentage: "availability.defaultSOTPercentage",
  default_spot_length: "availability.defaultSpotLength",
  minimum_spot_length: "availability.minimumSpotLength",
  maximum_spot_length: "availability.maximumSpotLength",
  pixel_width: "format.pixelWidth",
  pixel_height: "format.pixelHeight",
  audience_index: "audienceIndex",
  audience_on_target_percentage: "audienceOnTargetPercentage",
  source_system_frame_id: "sourceSystemFrameID",
};

// US columns for Search Grid

export const searchGridColumns = [
  columns.frame_id,
  columns.spot_id,
  columns.image,
  columns.mo_frame_reference,
  columns.source_system,
  columns.media_owner,
  columns.formatGroup,
  columns.format,
  columns.environment,
  columns.price,
  columns.audience_on_target_percentage,
  columns.audience_index,
  columns.illumination_type,
  columns.orientation, // Shape
  columns.height,
  columns.width,
  columns.size,
  columns.state,
  columns.tv_region, // DMA
  columns.has_motion,
  columns.cbsa,
  columns.county,
  columns.country,
  columns.city,
  columns.address, // Location Description
  columns.postcode, // Zip Code
  columns.latitude,
  columns.longitude,
  columns.surface_area,
  columns.nearest_poi,
  columns.poi_address,
  columns.poi_distance_range_bucket,
  columns.poi_distance_from_frame,
  columns.direction,
  columns.number_of_associated_POIs,
  columns.prohibition_status,
  columns.default_sot_percentage,
  columns.default_spot_length,
  columns.minimum_spot_length,
  columns.maximum_spot_length,
  columns.pixel_width,
  columns.pixel_height,
  columns.source_system_frame_id,
];

export const columnsWidth = {
  [columns.format]: 120,
  [columns.formatGroup]: 120,
  [columns.frame_id]: 102,
  [columns.spot_id]: 102,
  [columns.image]: 60,
  [columns.source_system]: 110,
  [columns.mo_frame_reference]: 150,
  [columns.media_owner]: 118,
  [columns.environment]: 140,
  [columns.price]: 80,
  [columns.illumination_type]: 120,
  [columns.orientation]: 95,
  [columns.has_motion]: 90,
  [columns.postcode]: 82,
  [columns.latitude]: 90,
  [columns.longitude]: 90,
  [columns.address]: 150,
  [columns.height]: 65,
  [columns.width]: 65,
  [columns.audience_index]: 120,
  [columns.audience_on_target_percentage]: 160,
  [columns.size]: 130,
  [columns.surface_area]: 100,
  [columns.nearest_poi]: 100,
  [columns.poi_address]: 140,
  [columns.poi_distance_range_bucket]: 135,
  [columns.poi_distance_from_frame]: 135,
  [columns.tv_region]: 135,
  [columns.cbsa]: 135,
  [columns.city]: 135,
  [columns.county]: 115,
  [columns.country]: 115,
  [columns.state]: 115,
  [columns.direction]: 90,
  [columns.number_of_associated_POIs]: 170,
  [columns.prohibition_status]: 150,
  [columns.default_sot_percentage]: 80,
  [columns.default_spot_length]: 80,
  [columns.minimum_spot_length]: 80,
  [columns.maximum_spot_length]: 80,
  [columns.pixel_width]: 80,
  [columns.pixel_height]: 80,
  [columns.source_system_frame_id]: 60,
};

export const features = {
  supplierContractNumber: "supplierContractNumber",
  contracting: "contracting",
  tradingModeAndAvails: "tradingModeAndAvails",
  rfp: "rfp",
};

export const featuresEnabled = {
  [features.supplierContractNumber]: true,
  [features.contracting]: true,
  [features.tradingModeAndAvails]: false,
  [features.rfp]: true,
};

export const columnsSortable = {};

export const columnsDraggable = {};

export const mandatoryColumns = [];

export const userSelectedColumns = [
  columns.format,
  columns.formatGroup,
  columns.frame_id,
  columns.spot_id,
  columns.image,
  columns.media_owner,
  columns.environment,
  columns.orientation,
  columns.latitude,
  columns.longitude,
  columns.tv_region,
  columns.postcode,
  columns.address,
  columns.height,
  columns.width,
  columns.size,
  columns.nearest_poi,
  columns.poi_address,
  columns.poi_distance_range_bucket,
  columns.poi_distance_from_frame,
  columns.state,
];

export const pinnedRightColumns = [];

export const aatColumns = [
  { columnType: AATColumn.audienceName, field: "audienceName", header: "Audience", width: 150 } as AATColumnTemplate,
  { columnType: AATColumn.totalFrames, field: "totalFrames", header: "Total Frames", width: 75 } as AATColumnTemplate,
  { columnType: AATColumn.reach, field: "reach", header: "Reach", width: 75 } as AATColumnTemplate,
  {
    columnType: AATColumn.averageFrequency,
    field: "average_frequency",
    header: "Frequency",
    width: 75,
  } as AATColumnTemplate,
  {
    columnType: AATColumn.allPersonsImpressions,
    field: "all_persons_impressions",
    header: "All Persons Impressions",
    width: 75,
  } as AATColumnTemplate,
  { columnType: AATColumn.impacts, field: "impacts", header: "Impressions", width: 75 } as AATColumnTemplate,
  {
    columnType: AATColumn.reachPercentage,
    field: "reach_percentage",
    header: "Reach (%)",
    width: 75,
  } as AATColumnTemplate,
  {
    columnType: AATColumn.grossRatingPoints,
    field: "gross_rating_points",
    header: "GRP",
    width: 75,
  } as AATColumnTemplate,
  { columnType: AATColumn.trp, field: "trp", header: "TRP", width: 75 } as AATColumnTemplate,
  { columnType: AATColumn.audienceId, field: "audienceId", header: "", width: 75 } as AATColumnTemplate,
];
