<h4 mat-dialog-title class="listhead-tab">
  <img src="../../../assets/images/POI.svg" alt="" class="img-25" /> POI & Associated {{ "frames" | translate }} Details
  <span class="pull-right m-0"
    ><img
      src="../../../../../assets/images/close-round-blue.svg"
      alt=""
      class="cursor-pointer img-20"
      (click)="closeDialog()"
  /></span>
</h4>
<hr />
<div>
  <table>
    <tr>
      <th>Name:</th>
      <td>{{ poiDetails.name }}</td>
    </tr>
    <tr>
      <th>NAICS Code:</th>
      <td>{{ poiDetails.classificationCode }}</td>
    </tr>
    <tr>
      <th>Category Name:</th>
      <td>{{ poiDetails.categoryName }}</td>
    </tr>
    <tr>
      <th>Subcategory Name:</th>
      <td>{{ poiDetails.subCategoryName }}</td>
    </tr>
    <tr>
      <th>Address:</th>
      <td>{{ poiDetails.address }}</td>
    </tr>
  </table>
</div>

<div class="table-wrap">
  <ngx-datatable
    [columns]="frameColumns"
    [rows]="frames"
    [virtualization]="true"
    [scrollbarH]="true"
    [scrollbarV]="true"
    [sorts]="[{ prop: 'distanceFromPOI', dir: 'asc' }]"
    [columnMode]="'force'"
  >
  </ngx-datatable>
</div>
<hr />
<div>
  <button class="primary-btn" (click)="closeDialog()">Close</button>
  <div></div>
</div>
