import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "execute",
})
export class GenericPipe implements PipeTransform {
  transform(value: any, context: any, executor: Function, ...otherArgs: any[]): any {
    return executor.apply(context, [value, ...otherArgs]);
  }
}
