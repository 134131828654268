import { Injectable } from "@angular/core";
import { Store } from "./store";
import { Subject } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";

@Injectable({
  providedIn: "root",
  useFactory: () => new PersistenceService(localStorage),
})
export class PersistenceService {
  private storageChange$ = new Subject<{ key: string; value: any }>();

  constructor(private storage: Storage) {
    window.addEventListener("storage", this.storageEventListener.bind(this));
  }

  public storageChange() {
    return this.storageChange$.asObservable().pipe(distinctUntilChanged());
  }

  // TODO: Deprecate direct access
  get(key: string): string {
    return this.storage.getItem(key);
  }

  // TODO: Deprecate direct access
  set(key: string, value: string): boolean {
    try {
      this.storage.setItem(key, value);
      return true;
    } catch (err) {
      return false;
    }
  }

  store(key: string): Store {
    if (this.get(key) == undefined) {
      this.set(key, "{}");
    }
    return {
      get: (_key) => JSON.parse(this.get(key))[_key] as string,
      set: (_key, value) => this.set(key, JSON.stringify({ ...JSON.parse(this.get(key)), [_key]: value })),
      clear: () => this.set(key, "{}"),
      delete: () => this.storage.removeItem(key),
    };
  }

  private storageEventListener = (event: StorageEvent): void => {
    if (event.storageArea === localStorage) {
      let v;
      try {
        v = JSON.parse(event.newValue);
      } catch (e) {
        v = event.newValue;
      }
      this.storageChange$.next({ key: event.key, value: v });
    }
  };
}
