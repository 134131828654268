export enum ProhibitionType {
  NICOTINE = "NICOTINE",
  CANNABIS = "CANNABIS",
  GAMBLING = "GAMBLING",
  ALCOHOL = "ALCOHOL",
  VAPPING = "VAPPING",
  HFSS = "HFSS",
  POLITICAL = "POLITICAL",
  NON_PROFIT = "NON_PROFIT",
}
