import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";

@Component({
  templateUrl: "./dialog.component.html",
  styleUrls: ["./dialog.component.scss"],
  selector: "app-dialog",
})
export class DialogComponent {
  @Input()
  accentColor: string;

  @Input()
  icon: string;

  @Input()
  title: string;

  @Input()
  message: string;

  @Input()
  secondaryMessage: string;

  @Input()
  primaryActionLabel: string;

  @Input()
  secondaryActionLabel: string;

  @Output()
  primaryClick = new EventEmitter();

  @Output()
  secondaryClick = new EventEmitter();

  onPrimaryClick() {
    this.primaryClick.emit();
  }

  onSecondaryClick() {
    this.secondaryClick.emit();
  }
}
