import { Injectable } from "@angular/core";
import { UserService } from "./resource-svc/user/user.service";
import { PersistenceService } from "./persistence/persistence.service";

@Injectable({
  providedIn: "root",
})
export class PreferencesService {
  constructor(private users: UserService, private persistence: PersistenceService) {}

  set(key: string, value: string) {
    return this.store().then((store) => store.set(key, value));
  }

  get(key: string) {
    return this.store().then((store) => store.get(key));
  }

  private store() {
    return this.users
      .getUserId()
      .then((id) => `preferences:${id}`)
      .then((key) => this.persistence.store(key));
  }
}
