/* tslint:disable:member-ordering */
import { Injectable } from "@angular/core";
import { ApiService } from "./api/api.service";
import { PersistenceService } from "./persistence/persistence.service";
import { JwtHelper } from "./JwtHelper";
import { EMPTY, of, ReplaySubject, Subject } from "rxjs";
import { delay, distinctUntilChanged, filter, flatMap, map, switchMap } from "rxjs/operators";
import { Store } from "./persistence/store";
import { AuthorizationInfo, claimKeys } from "./authorization-info";
import { Router } from "@angular/router";
import { scheduleMacroTask } from "../common/utils";
import { AuthService } from "@auth0/auth0-angular";

@Injectable({
  providedIn: "root",
})
export class AuthorizationService {
  get loggedIn$() {
    return this.info$
      .asObservable()
      .pipe(map((info) => this.loggedIn(info)))
      .pipe(distinctUntilChanged());
  }

  get accessToken$() {
    return this.info$
      .asObservable()
      .pipe(map((info) => this.accessToken(info)))
      .pipe(distinctUntilChanged());
  }

  logout() {
    this.set(undefined);
    this.auth.logout({ returnTo: window.location.origin });
    this.router.navigate(["/"]);
  }

  // refreshSession(callback) {
  //   this.lock.checkSession({}, result => {
  //     callback(result);
  //   });
  // }

  get authorities$() {
    return this.info$
      .asObservable()
      .pipe(map((info) => this.authorities(info)))
      .pipe(distinctUntilChanged());
  }

  // TODO: Refactor all usages to refer to this
  get authenticatedUserDetails$() {
    return this.info$
      .asObservable()
      .pipe(map((info) => this.authenticatedUserDetails(info)))
      .pipe(distinctUntilChanged());
  }

  get userName$() {
    return this.info$
      .asObservable()
      .pipe(map((info) => this.userName(info)))
      .pipe(distinctUntilChanged());
  }

  private info$: Subject<AuthorizationInfo>;
  // private router: Router
  private store: Store;
  private CHECK_INTERVAL = 30000;
  private AUTH_STORAGE_KEY = "authorization";
  private isNewAccessTokenFetched = false;

  public loginAuth0() {
    console.log("Will loginWithRedirect");
    this.auth.loginWithRedirect();
  }

  constructor(
    public auth: AuthService,
    private apiClient: ApiService,
    private jwtHelper: JwtHelper,
    persistence: PersistenceService,
    public router: Router
  ) {
    console.log("Auth constructor loaded..");
    this.store = persistence.store(this.AUTH_STORAGE_KEY);
    this.loginIfNotAuthenticated();

    persistence
      .storageChange()
      .pipe(filter((newValue) => newValue.key === this.AUTH_STORAGE_KEY))
      .subscribe((newValue) => {
        if (newValue.value["info"] == null) {
          // console.debug("LocalStorage info value changed to ", newValue.value['info'])
          this.info$.next(undefined);
        } else {
          // console.debug("LocalStorage info value changed to ", newValue)
          this.info$.next(JSON.parse(newValue.value["info"]));
        }
      });

    this.info$ = new ReplaySubject(1);
    this.loggedIn$
      .pipe(filter((loggedIn) => !loggedIn))
      .pipe(filter(() => router.navigated))
      // .pipe(tap(() => console.debug("User logged out, redirecting to LoginPage")))
      .subscribe(() => router.navigate(["/login"]));
    this.loggedIn$
      .pipe(filter((loggedIn) => loggedIn))
      .pipe(filter(() => router.navigated))
      // .pipe(tap(() => console.debug("User Successfully loggedIn, redirecting to homepage")))
      .subscribe(() => router.navigate(["/"]));
    this.loggedIn$
      .pipe(switchMap((loggedIn) => (!loggedIn ? EMPTY : this.getNextInfo$())))
      .subscribe((info) => this.info$.next(info));
    scheduleMacroTask(() => this.initializeInfo());
  }

  private loginIfNotAuthenticated() {
    console.log("loginIfNotAuthenticated...");
    if (!this.isNewAccessTokenFetched) {
      console.log("Attempting getAccessTokenSilently");
      this.auth.getAccessTokenSilently({ ignoreCache: false, detailedResponse: true }).subscribe(
        (result) => {
          console.log("got Access Token Silently ", result !== undefined);
          this.isNewAccessTokenFetched = true;
          this.setApplicableNavigationBasedOnResponse(result);
        },
        (e) => {
          console.log("User not logged in", e);
          this.auth.loginWithRedirect();
        }
      );
    }
  }

  private setApplicableNavigationBasedOnResponse(result: any) {
    if (this.hasAnyExistingTokenInStore()) {
      const authInfo: AuthorizationInfo = this.get();
      if (this.isTokenValid(authInfo.access_token)) {
        this.updateAuthInfoWithNewTokenAndSetNavigation(result);
      } else {
        console.log("Token Expired, User needs to re-authenticate");
        this.logout();
      }
    } else {
      this.updateAuthInfoWithNewTokenAndSetNavigation(result);
    }
  }

  private updateAuthInfoWithNewTokenAndSetNavigation(result: any) {
    this.store.clear();
    this.set({
      access_token: result.access_token,
      logged_in: true,
      expires_in: result.expires_in,
    } as AuthorizationInfo);

    this.info$.complete();
    this.info$ = new ReplaySubject(1);

    const currentUrl: string = window.location.pathname;
    console.log("currentUrl", currentUrl);
    const navigateToUrl: string = currentUrl === "/login" ? "/" : currentUrl;
    console.log("navigateToUrl", navigateToUrl);
    this.router.navigate([navigateToUrl]).then(() => {
      console.log("Successfully logged in");
    });
  }

  private hasAnyExistingTokenInStore(): boolean {
    return this.get() !== undefined;
  }

  private getNextInfo$() {
    console.log("get getNextInfo");
    return this.info$.asObservable().pipe(
      filter((info) => !!info),
      flatMap((info) => of(info).pipe(delay(this.CHECK_INTERVAL)))
    );
  }

  private initializeInfo() {
    const info = this.get();
    this.info$.next(info);
  }

  private set(info: AuthorizationInfo) {
    this.store.set("info", info == undefined ? null : JSON.stringify(info));
    // tslint:disable-next-line:max-line-length
    this.store.set(
      "userDetails",
      info != undefined && info.access_token != undefined ? this.jwtHelper.decodeToken(info.access_token) : null
    );
    this.info$.next(info);
  }

  private get(): AuthorizationInfo | undefined {
    const result = this.store.get("info");
    if (result == undefined) {
      return undefined;
    }
    const info = JSON.parse(result) as AuthorizationInfo;
    return info.access_token != undefined && info.access_token !== "null" ? info : undefined;
  }

  private loggedIn(info: AuthorizationInfo, tolerance = 0) {
    if (info == undefined) {
      return false;
    }
    const { logged_in } = info;
    return logged_in && this.isTokenValid(info.access_token);
  }

  private authorities(info: AuthorizationInfo) {
    return this.getClaimOrDefault(info, claimKeys.authorities, []);
  }

  private authenticatedUserDetails(info: AuthorizationInfo) {
    if (info == undefined) {
      return {};
    }
    const { access_token } = info;
    const customClaims: { [key: string]: any } = this.jwtHelper.decodeToken(access_token)[claimKeys.namespace];
    return {
      accessToken: access_token,
      userId: customClaims[claimKeys.user_name],
      authorities: customClaims[claimKeys.authorities],
    };
  }

  private userName(info: AuthorizationInfo) {
    return this.getClaimOrDefault(info, claimKeys.user_name, "");
  }

  private getClaimOrDefault(info: AuthorizationInfo, claim: string, defaultValue: any) {
    if (info == undefined) {
      return defaultValue;
    }
    const { access_token } = info;
    return this.jwtHelper.decodeToken(access_token)[claimKeys.namespace][claim];
  }

  private isTokenValid(token: string): boolean {
    // tslint:disable-next-line:radix
    return !this.expired(parseInt(this.jwtHelper.decodeToken(token)["exp"]) * 1000);
  }

  private expired(expiry_date: number, safe_tolerance = 0) {
    return Date.now() > expiry_date - safe_tolerance;
  }

  private accessToken(info: AuthorizationInfo) {
    return info == undefined ? undefined : info.access_token;
  }
}
