<header *ngIf="loggedIn" class="header l-header">
  <div class="l-header_inner clearfix">
    <!-- logo -->
    <div class="logo">
      <img src="assets/images/talon_logo.png" (click)="goToHome()" />
    </div>
    <!-- search form -->
    <div *ngIf="loggedIn && !isCreativeAgent" class="menu-search pl-search">
      <div class="regions" *ngIf="showRegions()">
        <app-regions></app-regions>
      </div>
      <app-notification-icon
        *ngIf="notificationsEnabled && config.inAppNotification && !isExternalAgent"
        (click)="toggleNotifications()"
        [unreadNotifications]="unreadAppNotifications"
        [notificationIcon]="notificationIcon"
        class="d-flex justify-content-center header-notification col-md-1 col-sm-1 col-1 form-group menu-s-btn pr-0"
      >
      </app-notification-icon>
    </div>
    <!-- search form -->
    <div *ngIf="loggedIn" class="header-icons-group">
      <!-- saved search -->
      <div class="login-details">
        <span>Logged in as:</span>
        <ul class="p-0 m-0 list-inline">
          <li placement="bottom" [ngbTooltip]="userName">{{ userName }}</li>
          <li></li>
          <!-- <li>Planning</li> -->
          <li><a class="logout-txt" (click)="logout()">Logout</a></li>
        </ul>
      </div>
    </div>
  </div>
</header>
<app-notification
  [showNotifications]="showAppNotifications"
  [notificationType]="notificationType.CAMPAIGN_UPDATE"
  (handleCloseClick)="handleNotificationsCloseClick()"
  [notifications]="appNotificationsOnDisplay"
  [title]="appNotificationTitle"
  (handleNotificationClick)="onHandleNotificationClick($event)"
  (handleScroll)="loadNotificationsNextPage()"
  (handleScrollUp)="loadNotificationsPrevPage()"
  [showLoader]="showNotificationLoader"
></app-notification>
<!-- <app-feedback></app-feedback> -->
