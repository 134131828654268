<div class="dialog" data-cy="dialog">
  <div class="dialog-accent {{ accentColor }}"></div>
  <div class="content">
    <div class="dialog-header">
      <mat-icon [fontIcon]="icon" class="{{ accentColor }}"></mat-icon>
      <span class="title">{{ title }}</span>
    </div>
    <div class="dialog-body">
      <div *ngIf="message" class="message">{{ message }}</div>
      <div *ngIf="secondaryMessage" class="secondary-message">
        {{ secondaryMessage }}
      </div>
      <div class="wrapped-content">
        <ng-content></ng-content>
      </div>
    </div>
    <div class="dialog-footer">
      <div class="action-buttons">
        <button class="primary-btn" [mat-dialog-close]="true" (click)="onPrimaryClick()" data-cy="dialog-primary-btn">
          {{ primaryActionLabel }}
        </button>
        <button
          *ngIf="secondaryActionLabel"
          class="secondary-btn"
          [mat-dialog-close]="true"
          (click)="onSecondaryClick()"
          data-cy="dialog-secondary-btn"
        >
          {{ secondaryActionLabel }}
        </button>
      </div>
    </div>
  </div>
</div>
