import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { AuthorizationService } from "../../svc/authorization.service";
import { UserService } from "../../svc/resource-svc/user/user.service";
import { scheduleMacroTask, _window, _document } from "../../common/utils";
import { PersistenceService } from "../../svc/persistence/persistence.service";
import { APP_NOTIFICATION_CLICKED, NOTIFICATION_TYPE } from "../../common/constants";
import {
  AlertAndNotificationService,
  Filter,
  FitlerType,
  TargetType,
  InAppNotification,
  InAppType,
  TypeFilter,
  FromDateFilter,
  TillDateFilter,
} from "alert-and-notification";
import { FeatureToggleService } from "../../svc/resource-svc/featureToggle/featureToggle.service";
import { ConfigurationService } from "../../svc/configuration-service";

interface NotificationConfig {
  requiredFields: string[];
  relativeUrlTemplate: string;
}

interface Notification extends InAppNotification {
  onClickUrl: string;
}

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  @Output() handleNotificationClick = new EventEmitter<string>();

  environment: any;
  config: any;
  loggedIn = false;
  userName = "";
  notificationsEnabled = false;
  showAppNotifications = false;
  unreadAppNotifications = 0;
  notificationIcon = "notification.svg";
  notificationType = NOTIFICATION_TYPE;
  filters: Array<Filter> = [];
  inappFilter: TypeFilter;
  tillDateFilter: TillDateFilter;
  currentNotificationsPageNumber = 0;
  showNotificationLoader = false;
  appNotifications: InAppNotification[] = [];
  newNotifications$: Subscription;
  appNotificationsOnDisplay: Notification[];
  clickableNotificationConfig$: Observable<Map<InAppType, NotificationConfig>>;
  appNotificationTitle = "Campaign Notifications";
  isExternalAgent = false;
  isCreativeAgent = false;
  _showRegions = false;

  constructor(
    private authorization: AuthorizationService,
    private persistence: PersistenceService,
    private userService: UserService,
    private http: HttpClient,
    private notificationService: AlertAndNotificationService,
    private router: Router,
    private featureToggleService: FeatureToggleService,
    private configurationService: ConfigurationService
  ) {
    this.tillDateFilter = {
      kind: FitlerType.TILLDATE,
      tillDate: new Date().toISOString(),
    };
    this.inappFilter = {
      kind: FitlerType.NOTIFICATIONTYPE,
      type: InAppType.CHAT,
      negate: true,
    };
  }

  ngOnInit() {
    this.environment = this.configurationService.getConfig();
    this.config = this.environment.headerConfig;
    this.notificationsEnabled = this.environment.notificationsEnabled;
    this.authorization.loggedIn$.subscribe((loggedIn) => (this.loggedIn = loggedIn));
    this.authorization.loggedIn$.pipe(filter((loggedIn) => !loggedIn)).subscribe(() => {
      this.userName = "";
    });

    this.authorization.userName$.subscribe((name) => (this.userName = name as string));

    this.clickableNotificationConfig$ = this.http.get<Map<InAppType, NotificationConfig>>(
      "assets/json/clickableNotificationConfig.json"
    );
    this.clickableNotificationConfig$.subscribe((config) => {
      this.appNotificationsOnDisplay = this.enrichNotifications(config, this.appNotifications);
    });

    this.authorization.accessToken$.subscribe((token) => {
      this.authorization.loggedIn$.subscribe((isLoggedIn) => {
        if (token && isLoggedIn) {
          return scheduleMacroTask(() =>
            this.userService.getUserId().then((id) => {
              this.featureToggleService
                .isFeatureEnabled("UI_SHOW_REGIONS")
                .subscribe((show) => (this._showRegions = show));

              this.filters = [this.getUserFilter(id)];
              this.currentNotificationsPageNumber = 0;
              if (this.notificationsEnabled) {
                this.getAllNotifications();
                this.listenToNewNotifications(token);
              }
            })
          );
        }
      });
    });

    const roles = this.authorization.authorities$;
    roles.subscribe((data) => {
      this.isExternalAgent = data.includes("ROLE_EXTERNAL_AGENCY");
      this.isCreativeAgent = data.includes("ROLE_CREATIVE_USER");
    });
  }

  logout() {
    this.authorization.logout();
  }

  private getAllNotifications() {
    this.showNotificationLoader = true;
    // tslint:disable-next-line:max-line-length
    this.notificationService
      .getAllNotifications(
        [...this.filters, this.inappFilter, this.tillDateFilter],
        this.currentNotificationsPageNumber
      )
      .subscribe(
        (data) => {
          this.populateNotifications(data, NOTIFICATION_TYPE.CAMPAIGN_UPDATE, true);
          if (this.currentNotificationsPageNumber == 0) {
            this.updateUnreadAppNotifications();
          }
          this.showNotificationLoader = false;
        },
        (err) => {
          this.showNotificationLoader = false;
        }
      );
  }

  private listenToNewNotifications(token: string) {
    this.newNotifications$ = this.notificationService
      .getNotifications(token, this.filters)
      .subscribe((notification) => {
        this.updateNewNotification(notification);
      });
  }

  private populateNotifications(notifications: InAppNotification[], type: string, append: boolean) {
    if (type === NOTIFICATION_TYPE.CAMPAIGN_UPDATE) {
      this.clickableNotificationConfig$.subscribe((config) => {
        if (append) {
          this.appNotifications = [...this.appNotifications, ...notifications];
          this.appNotificationsOnDisplay = [
            ...this.appNotificationsOnDisplay,
            ...this.enrichNotifications(config, notifications),
          ];
        } else {
          this.appNotifications = [...notifications, ...this.appNotifications];
          this.appNotificationsOnDisplay = [
            ...this.enrichNotifications(config, notifications),
            ...this.appNotificationsOnDisplay,
          ];
        }
      });
    }
  }

  private updateNewNotification(notification) {
    if (notification.inappType === NOTIFICATION_TYPE.CAMPAIGN_UPDATE) {
      this.unreadAppNotifications += 1;
      this.populateNotifications([notification], NOTIFICATION_TYPE.CAMPAIGN_UPDATE, false);
    }
  }

  private updateUnreadAppNotifications() {
    const lastOpened = JSON.parse(this.persistence.get(APP_NOTIFICATION_CLICKED));
    if (lastOpened) {
      const unreadCountFilter: FromDateFilter = {
        kind: FitlerType.FROMDATE,
        fromDate: lastOpened,
      };
      this.notificationService
        .getNotificationsCount([...this.filters, this.inappFilter, unreadCountFilter, this.tillDateFilter])
        .subscribe((data) => {
          this.unreadAppNotifications = data;
        });
    } else {
      this.notificationService
        .getNotificationsCount([...this.filters, this.inappFilter, this.tillDateFilter])
        .subscribe((data) => {
          this.unreadAppNotifications = data;
        });
    }
  }

  private getUserFilter(username: string): Filter {
    return {
      kind: FitlerType.TARGET,
      type: TargetType.PLATO_USER_ID,
      values: [username],
    };
  }

  private enrichNotifications(
    config: Map<InAppType, NotificationConfig>,
    notifications: InAppNotification[]
  ): Notification[] {
    return notifications.map((notification) =>
      Object.assign({}, notification, { onClickUrl: this.getOnClickUrl(config, notification) })
    );
  }

  private getOnClickUrl(configurations: Map<InAppType, NotificationConfig>, notification: InAppNotification): string {
    const config: NotificationConfig[] = Object.keys(notification)
      .filter((key) => key === "inappType")
      .map((inappTypeKey) => notification[inappTypeKey])
      .map((inAppType) => (configurations[inAppType] ? configurations[inAppType] : ({} as NotificationConfig)));

    const additionalInfo: Map<string, any>[] = config.map((conf) => {
      if (Object.keys(conf).length === 0 || !notification.content.additionalInfo) {
        return {} as Map<string, any>;
      }
      return conf.requiredFields.map((requiredField) =>
        Object.keys(notification.content.additionalInfo).includes(requiredField)
      )
        ? notification.content.additionalInfo
        : ({} as Map<string, any>);
    });

    const url: string[] = additionalInfo.map((info) =>
      this.generateUrlFromTemplate(config[0].relativeUrlTemplate, config[0].requiredFields, info)
    );

    return url.length > 0 ? url[0] : "";
  }

  private generateUrlFromTemplate(template: string, templateFields: string[], fieldValueMap: Map<string, any>): string {
    if (Object.keys(fieldValueMap).length === 0) {
      return "";
    }
    templateFields.forEach((field) => (template = template.replace(`{{${field}}}`, fieldValueMap[field])));
    const templateQueryParams = template
      .split("?")
      .slice(1)[0]
      .split("&")
      .reduce((a, b) => {
        const c = b.split("=");
        a[c[0]] = decodeURIComponent(c[1]);
        return a;
      }, {});
    Object.keys(templateQueryParams).forEach((key) => {
      if (templateQueryParams[key] === "undefined") {
        template = template.replace("&" + key + "=undefined", "");
      }
    });
    return template;
  }

  toggleNotifications() {
    this.handleNotificationsToggle();
  }

  handleNotificationsCloseClick() {
    if (this.showAppNotifications) {
      this.handleNotificationsToggle();
    }
  }

  handleNotificationsToggle() {
    this.showAppNotifications = !this.showAppNotifications;
    if (this.showAppNotifications) {
      this.persistence.set(APP_NOTIFICATION_CLICKED, JSON.stringify(new Date()));
      this.unreadAppNotifications = 0;
    }
  }

  onHandleNotificationClick(value) {
    if (value === this.notificationType.CAMPAIGN_UPDATE) {
      this.handleNotificationsToggle();
    }
  }

  goToHome() {
    this.router.navigateByUrl("/");
  }

  loadNotificationsNextPage() {
    this.currentNotificationsPageNumber += 1;
    this.getAllNotifications();
  }

  loadNotificationsPrevPage() {}

  showRegions() {
    return this._showRegions;
  }
}
