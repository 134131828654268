import { Component, OnInit } from "@angular/core";
import { AuthorizationService } from "src/app/core/svc/authorization.service";
import { filter } from "rxjs/operators";

@Component({
  selector: "app-feedback",
  templateUrl: "./feedback.component.html",
  styleUrls: ["./feedback.component.scss"],
})
export class FeedbackComponent implements OnInit {
  private SCRIPT_ID = "feedback-widget";
  private COMPONENT_SELECTOR = "#jsd-widget";
  private get scriptLoaded() {
    return document.querySelector(`#${this.SCRIPT_ID}`) != undefined;
  }
  private get component() {
    return document.querySelector(this.COMPONENT_SELECTOR);
  }
  private get componentLoaded() {
    return this.component != undefined;
  }
  constructor(private authorization: AuthorizationService) {}

  ngOnInit() {
    this.authorization.loggedIn$.pipe(filter((loggedIn) => loggedIn)).subscribe(() => this.loadComponent());
    this.authorization.loggedIn$.pipe(filter((loggedIn) => !loggedIn)).subscribe(() => this.removeComponent());
  }

  private loadScript() {
    if (this.scriptLoaded) return;
    const script: HTMLElement = document.createElement("script");
    script["src"] = "https://jsd-widget.atlassian.com/assets/embed.js";
    script["id"] = this.SCRIPT_ID;
    script.setAttribute("data-jsd-embedded", undefined);
    script.setAttribute("data-key", "2fa0fde3-5582-44a9-a7f8-fb98b9cfef75");
    script.setAttribute("data-base-url", "https://jsd-widget.atlassian.com");
    script.onload = () => this.loadComponent();
    document.body.appendChild(script);
  }

  private loadComponent() {
    if (!this.scriptLoaded) return this.loadScript();
    if (this.componentLoaded) return;
    const event = document.createEvent("Event");
    event.initEvent("DOMContentLoaded", true, true);
    window.document.dispatchEvent(event);
  }

  private removeComponent() {
    if (!this.componentLoaded) return;
    this.component.remove();
  }
}
