import { Component } from "@angular/core";
import { Region } from "src/app/core/models/domain/RegionFeaturesConfig";
import { ConfigurationService } from "src/app/core/svc/configuration-service";
import { UserService } from "src/app/core/svc/resource-svc/user/user.service";

@Component({
  selector: "app-regions",
  templateUrl: "./regions.component.html",
})
export class RegionsComponent {
  regions: Array<Region>;
  selectedRegion: Region;

  constructor(private configurationService: ConfigurationService, userService: UserService) {
    const configuration = configurationService.getConfig();
    this.regions = userService
      .getRegions()
      ?.filter((r) => (configuration?.supportedRegions || []).indexOf(r) >= 0)
      .sort();
    this.selectedRegion = configurationService.getCurrentRegion()
      ? Region[configurationService.getCurrentRegion()]
      : this.regions?.[0];
  }

  changeRegion(region: Region) {
    this.configurationService.setCurrentRegion(region.toString());
  }
}
