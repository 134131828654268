import { Inject, Injectable, InjectionToken, Optional } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { ConfigurationService } from "src/app/core/svc/configuration-service";
import { Observable } from "rxjs";
import { debounce } from "lodash";

const environment = new ConfigurationService().getConfig();
const DEFAULT_BASE_URL = new InjectionToken<string>("default-base-url", {
  providedIn: "root",
  factory: () => "",
});

@Injectable({ providedIn: "root" })
export class ApiService {
  constructor(private httpClient: HttpClient, @Optional() @Inject(DEFAULT_BASE_URL) private baseUrl: string = "") {}

  get<T>(endpoint: string): Observable<any> {
    return this.makeRequest<T>("GET", this.buildUrl(endpoint));
  }

  post<T>(endpoint: string, object: T, headers?: any): Observable<any> {
    return this.makeRequest<T>("POST", this.buildUrl(endpoint), object, headers);
  }

  put<T>(endpoint: string, objectUpdated: T): Observable<any> {
    return this.makeRequest<T>("PUT", this.buildUrl(endpoint), objectUpdated);
  }

  patch<T>(endpoint: string, objectUpdated: T): Observable<any> {
    return this.makeRequest<T>("PATCH", this.buildUrl(endpoint), objectUpdated);
  }

  delete<T>(endpoint: string, objectUpdated?: T): Observable<any> {
    return this.makeRequest<T>("DELETE", this.buildUrl(endpoint), objectUpdated);
  }

  makeRequest<T>(method: string, endpoint: string, body?: any, headers?: any): Observable<any> {
    return this.httpClient.request<T>(method, this.constructFullyQualifiedURL(endpoint), {
      body,
      headers,
    });
  }

  makeRequestWithOptions(method: string, endpoint: string, body: any, options: any): Observable<any> {
    options.body = body;
    return this.httpClient.request(method, this.constructFullyQualifiedURL(endpoint), options);
  }

  // eslint-disable-next-line no-use-before-define
  makeRequestWithFullyQualifiedURLAndOptions(
    method: string,
    fullyQualifiedURL: string,
    body: any,
    options: any
  ): Observable<any> {
    options.body = body;
    return this.httpClient.request(method, fullyQualifiedURL, options);
  }

  public constructFullyQualifiedURL(endpoint: string): string {
    if (endpoint.indexOf("http://localhost") < 0) {
      endpoint = environment.apiUrl + endpoint;
    }
    return this.addLocaleQueryParam(endpoint);
  }

  public addLocaleQueryParam(endpoint: string): string {
    const urlObject = new URL(endpoint, "http://dummyhost");
    const hasQueryParams: boolean = urlObject.search.length > 0;
    if (hasQueryParams) {
      return this.addLocaleToURLWithoutQueryParams(endpoint);
    } else {
      return this.addLocaleToURLHavingQueryParams(endpoint);
    }
  }

  public readFileNameFromHeaders(response: HttpResponse<Blob>, fallbackName: string): string {
    let fileName = fallbackName;
    const contentDisposition = response.headers?.get("Content-Disposition");
    if (contentDisposition) {
      const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = fileNameRegex.exec(contentDisposition);
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, "");
      }
    }
    return fileName;
  }

  private addLocaleToURLWithoutQueryParams(endpoint: string): string {
    if (endpoint.endsWith("&")) {
      return endpoint + `locale=${environment.locale}`;
    }
    return endpoint + `&locale=${environment.locale}`;
  }

  private addLocaleToURLHavingQueryParams(endpoint: string): string {
    if (endpoint.endsWith("?")) {
      return endpoint + `locale=${environment.locale}`;
    }
    return endpoint + `?locale=${environment.locale}`;
  }

  private buildUrl(endpoint: string): string {
    return `${this.baseUrl || ""}${endpoint}`;
  }
}

/**
 * Debounce a method
 */
export function Debounce(ms) {
  return function (target: any, key: any, descriptor: any) {
    const oldFunc = descriptor.value;
    const newFunc = debounce(oldFunc, ms);
    descriptor.value = function () {
      return newFunc.apply(this, arguments);
    };
  };
}
