import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "formatImpacts",
})
export class FormatImpactsPipe implements PipeTransform {
  transform(input: number): string {
    let exp,
      suffixes = ["K", "M", "G", "T", "P", "E"];

    if (!input || Number.isNaN(input)) {
      return null;
    }

    if (input < 1000) {
      return input.toString();
    }

    exp = Math.floor(Math.log(input) / Math.log(1000));
    const mantissa = input / Math.pow(1000, exp);

    if (mantissa < 10) {
      return mantissa.toFixed(1) + suffixes[exp - 1];
    }

    return mantissa.toFixed() + suffixes[exp - 1];
  }
}
