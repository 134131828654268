import { Component, EventEmitter, Inject, Input, Output } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-warning-dialog",
  templateUrl: "./warning-dialog.component.html",
  styleUrls: ["./warning-dialog.component.scss"],
})
export class WarningDialogComponent {
  @Output() closed = new EventEmitter<boolean>();
  @Input() message: string;
  @Input() secondaryMessage: string;
  @Input() secondaryActionLabel: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    if (data) {
      this.message = this.data.warningMessage;
    }
  }

  proceed(value: boolean) {
    this.closed.emit(value);
  }
}
