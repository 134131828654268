<app-dialog
  class="confirmation-dialog"
  [accentColor]="accentColor"
  [icon]="icon"
  [title]="title"
  [message]="message"
  [secondaryMessage]="secondaryMessage"
  [primaryActionLabel]="primaryActionLabel"
  (primaryClick)="onYes()"
  [secondaryActionLabel]="secondaryActionLabel"
  (secondaryClick)="onNo()"
>
</app-dialog>
