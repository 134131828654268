<app-dialog
  class="error-dialog"
  accentColor="error"
  icon="info"
  [title]="errorCategory"
  [message]="errorMessage"
  [secondaryMessage]="errorDetails"
  primaryActionLabel="Ok"
  (primaryClick)="close()"
>
  <ng-content></ng-content>
</app-dialog>
