import { Injectable, OnInit } from "@angular/core";
import { ResourceService } from "../resource-service.service";
import { User } from "../../../models/domain/user";
import { ApiService } from "../../api/api.service";
import { Observable, of } from "rxjs";
import { AuthorizationService } from "../../authorization.service";
import { filter, map } from "rxjs/operators";
import { Region } from "src/app/core/models/domain/RegionFeaturesConfig";
import { ConfigurationService } from "src/app/core/svc/configuration-service";
const environment = new ConfigurationService().getConfig();

export const ROLE_INVESTMENT = "ROLE_INVESTMENT";
export const ROLE_FINANCE = "ROLE_FINANCE";
export const REGION_PREFIX = "REGION_";

const AUTH_REGION = {
  CA: Region.CA,
  IE: Region.IE,
  UK: Region.UK,
  US: Region.US,
};
@Injectable({
  providedIn: "root",
})
export class UserService extends ResourceService<User> {
  private userProfile:
    | undefined
    | {
        accessToken: string;
        userId: string;
        authorities: [string];
      } = undefined;

  constructor(
    apiCall: ApiService,
    private authorization: AuthorizationService,
    configurationService: ConfigurationService
  ) {
    super(apiCall, "/auth/users");
    this.authorization.authenticatedUserDetails$.subscribe((profile) => {
      this.userProfile = {
        accessToken: profile.accessToken,
        userId: profile.userId as string,
        authorities: profile.authorities as [string],
      };
      if (!configurationService.getCurrentRegion())
        configurationService?.setCurrentRegion(this.getFirstOrDefaultRegion().toString());
    });
  }

  getUserId(): Promise<string> {
    return of(this.userProfile)
      .pipe(
        filter((p) => Boolean(p)),
        map((p) => p.userId)
      )
      .toPromise();
  }

  getAllUserFullNames(roles?: Array<string>, regionEnvironment?: string): Observable<Array<string>> {
    let userFullNamesEndpoint = "/auth/users/fullNames";
    let queryValue;
    if (roles) {
      queryValue = roles.join();
      userFullNamesEndpoint += "?roles=" + queryValue;
    }

    if (regionEnvironment) {
      if (userFullNamesEndpoint.includes("roles")) {
        userFullNamesEndpoint += "&regionEnvironment=" + regionEnvironment;
      } else {
        userFullNamesEndpoint += "?regionEnvironment=" + regionEnvironment;
      }
    }
    return this.apiCall.get(userFullNamesEndpoint).pipe(map((users) => users.sort()));
  }

  hasFinanceRole(): boolean {
    return this.hasRole("ROLE_FINANCE");
  }

  hasOptimisedPlanningBetaUserRole(): boolean {
    return this.hasRole("ROLE_ARISTOTLE");
  }

  private hasRole(role: string) {
    return this.userProfile?.authorities?.includes(role);
  }

  getRegions(): Array<Region> {
    return (
      this.userProfile?.authorities
        ?.filter((a) => a.startsWith(REGION_PREFIX))
        .map((r) => AUTH_REGION[r.replace(REGION_PREFIX, "")]) || []
    );
  }

  getFirstOrDefaultRegion(): Region {
    const regions = this.getRegions();
    return regions.length > 0 ? regions[0] : environment.defaultRegion;
  }
}
