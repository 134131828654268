import { Component, OnInit, Inject, EventEmitter, Output, Input } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-confirmation-dialog",
  templateUrl: "./confirmation-dialog.component.html",
  styleUrls: ["./confirmation-dialog.component.scss"],
})
export class ConfirmationDialogComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}
  @Output() public confirm = new EventEmitter<boolean>();

  @Input() message: string;
  @Input() secondaryMessage: string;
  @Input() primaryActionLabel: string = "Yes";
  @Input() secondaryActionLabel: string = "No";
  @Input() icon: string = "info";
  @Input() title: string = "Attention";
  @Input() accentColor: string = "primary";

  ngOnInit() {
    if (this.data) {
      this.message = this.data.msg;
      this.secondaryMessage = this.data.secondaryMessage;
      this.icon = this.data.icon ?? this.icon;
      this.title = this.data.title ?? this.title;
      this.accentColor = this.data.accentColor ?? this.accentColor;
      this.primaryActionLabel = this.data.primaryActionLabel ?? this.primaryActionLabel;
      this.secondaryActionLabel = this.data.secondaryActionLabel ?? this.secondaryActionLabel;
    }
  }

  onYes() {
    this.confirm.emit(true);
  }

  onNo() {
    this.confirm.emit(false);
  }
}
