export class CustomColor {
  r: number;
  g: number;
  b: number;
  a: number;

  constructor(r: number, g: number, b: number, a: number) {
    this.r = r;
    this.g = g;
    this.b = b;
    this.a = a;
  }
}

export function getRGBAString(color: CustomColor) {
  return `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;
}

export const COLORS = [
  [108, 80, 106],
  [153, 204, 204],
  [255, 204, 102],
  [255, 102, 102],
  [255, 204, 204],
  [255, 102, 204],
  [255, 157, 0],
  [255, 0, 177],
  [191, 88, 255],
  [102, 56, 255],
  [0, 147, 255],
  [0, 174, 181],
  [145, 100, 0],
  [226, 182, 0],
  [90, 55, 0],
  [181, 0, 126],
  [137, 129, 237],
  [0, 8, 198],
  [0, 112, 195],
  [0, 119, 124],
  [255, 134, 199],
  [121, 146, 195],
  [255, 90, 90],
  [0, 0, 0],
  [126, 126, 126],
  [198, 103, 8],
  [54, 211, 247],
  [76, 132, 10],
  [0, 53, 139],
  [172, 12, 12],
  [170, 161, 109],
];
