<div #container [ngClass]="{ 'chat-window': !showNotifications }">
  <div class="notification" *ngIf="showNotifications">
    <div class="notification-header">
      <h5>
        {{ title }}
        <img
          (click)="hideNotifications()"
          src="../../../../../assets/images/close-round.svg"
          class="img-16 pull-right cursor-pointer"
        />
      </h5>
    </div>
    <div class="notification-content" infiniteScroll [scrollWindow]="false" (scrolled)="onScroll()">
      <ul>
        <li *ngFor="let notification of notifications">
          <div class="notification-title">{{ notification.content.title }}</div>
          <div
            [ngClass]="{ clickable: notification.onClickUrl.length > 0 ? true : false, 'notification-body': true }"
            (click)="onClick(notification.onClickUrl)"
          >
            {{ notification.content.body }}
          </div>
          <div class="notification-owner">{{ notification.owner.label }}</div>
        </li>
      </ul>
      <div class="custom-loader" *ngIf="showLoader"></div>
    </div>
    <div class="notification-footer"></div>
  </div>
</div>
