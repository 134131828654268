import { Component, Input } from "@angular/core";
import { Observable } from "rxjs";
import { FileTypes, FileSaver } from "../../svc/file-saver.service";

@Component({
  selector: "app-excel-export",
  templateUrl: "./excel-export.component.html",
  styleUrls: ["./excel-export.component.scss"],
})
export class ExcelExportComponent {
  @Input() fileName: string;
  @Input() disabled: boolean;
  @Input() onExport: () => Observable<any>;

  exportInProgress = false;

  constructor(private fileSaver: FileSaver) {}

  export(event: Event): void {
    event.preventDefault();
    this.exportInProgress = true;
    this.onExport().subscribe(
      (fileData: any) => {
        this.fileSaver.saveFileToDisk(fileData, `${this.fileName}.xlsx`, FileTypes.XLSX);
        this.exportInProgress = false;
      },
      () => {
        this.exportInProgress = false;
      }
    );
  }
}
