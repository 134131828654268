import { Component, Input, Output, OnChanges, SimpleChanges, EventEmitter } from "@angular/core";
import { Subject } from "rxjs";
import { toPairs } from "../../../../core/common/utils";

interface ColumnValue {
  key: string;
  caption: string;
  selected: boolean;
}

export interface Captions {
  [key: string]: string;
}

@Component({
  selector: "app-column-toggler",
  templateUrl: "./column-toggler.component.html",
  styleUrls: ["./column-toggler.component.scss"],
})
export class ColumnTogglerComponent implements OnChanges {
  @Input() columns: string[];
  @Input() captions: Captions;
  @Input() selected: string[];
  @Output() selectionChange = new Subject<string[]>();
  @Output() onReset = new EventEmitter();
  values: ColumnValue[];

  ngOnChanges(changes: SimpleChanges) {
    this.populateValues();
  }

  onSaveClick(event: Event) {
    event.preventDefault();
    const selected = this.values.filter((column) => column.selected).map(({ key }) => key);
    this.selectionChange.next(selected);
  }

  private populateValues() {
    this.values = this.columns
      .map((column) => ({
        key: column,
        caption: toPairs(this.captions)
          .filter(([key, _]) => key === column)
          .shift()[1],
        selected: this.selected.some((key) => key === column),
      }))
      .sort((a, b) => (a.caption > b.caption ? 1 : a.caption < b.caption ? -1 : 0));
  }

  onResetClick(event: Event) {
    event.preventDefault();
    this.onReset.emit();
    this.populateValues();
  }
}
