import { Injectable } from "@angular/core";
import { ApiService } from "../../../../../../core/svc/api/api.service";
import { Observable } from "rxjs";
import { CustomPOISet } from "../../../../../../core/models/domain/poi/custompoi/customPOISet";
import { CustomPOIValidationResult } from "../../../../../../core/models/domain/poi/custompoi/CustomPOIValidationResult";

@Injectable({
  providedIn: "root",
})
export class CustomPoiSetService {
  constructor(private apiCall: ApiService) {}

  uploadFile(formData: FormData): Observable<any> {
    return this.apiCall.post("/poi-service/custom-poi-sets", formData);
  }

  validatePOISet(formData: FormData): Observable<CustomPOIValidationResult> {
    return this.apiCall.post("/poi-service/custom-poi-sets/validate", formData);
  }

  downloadValidationResult(formData: FormData): Observable<BlobPart> {
    return this.apiCall.makeRequestWithOptions("POST", "/poi-service/custom-poi-sets/validate/download", formData, {
      responseType: "blob" as "json",
    });
  }

  getAll(): Observable<CustomPOISet[]> {
    return this.apiCall.get("/poi-service/custom-poi-sets");
  }

  getById(id: string): Observable<CustomPOISet> {
    return this.apiCall.get(`/poi-service/custom-poi-sets/${id}`);
  }

  delete(id: string): Observable<any> {
    return this.apiCall.delete(`/poi-service/custom-poi-sets/${id}`);
  }

  downloadPOISetAsCSV(id: string) {
    return this.apiCall.makeRequestWithOptions(
      "GET",
      `/poi-service/custom-poi-sets/${id}/export`,
      {},
      {
        observe: "body",
        responseType: "blob",
        headers: {
          "Content-type": "application/json",
        },
      }
    );
  }

  getTemplate() {
    return this.apiCall.makeRequestWithOptions(
      "GET",
      `/poi-service/custom-poi-sets/template/download`,
      {},
      {
        observe: "body",
        responseType: "blob",
        headers: {
          "Content-type": "application/json",
        },
      }
    );
  }
}
