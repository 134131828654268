import { Component, ContentChild, Input, OnInit, ViewChild } from "@angular/core";
import { MatFormField, MatFormFieldControl } from "@angular/material/form-field";
import { EventEmitter } from "stream";

@Component({
  selector: "app-select",
  templateUrl: "./select.component.html",
  styleUrls: ["./select.component.scss"],
})
export class SelectComponent {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ContentChild("ngContent", { static: true }) _control: MatFormFieldControl<any>;
  @ViewChild("testForm", { static: true }) _matFormField: MatFormField;
  @Input() label: boolean;
  @Input() required: boolean;
  @Input() name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() valueChange: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() selectionChange: any;
}
