import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  EventEmitter,
  Output,
  SimpleChanges,
  OnChanges,
} from "@angular/core";
import { InAppNotification } from "alert-and-notification";
import { Router } from "@angular/router";

interface Notification extends InAppNotification {
  onClickUrl: string;
}

@Component({
  selector: "app-notification",
  templateUrl: "./notification.component.html",
  styleUrls: ["./notification.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class NotificationComponent implements OnChanges {
  @Input() showNotifications: boolean;
  @Input() title: string;
  @Input() notificationType: string;
  @Input() notifications: Notification[];
  @Input() showLoader = false;
  @Output() handleNotificationClick = new EventEmitter<string>();
  @Output() handleCloseClick = new EventEmitter();
  @Output() handleScroll = new EventEmitter();
  @Output() handleScrollUp = new EventEmitter();

  constructor(private router: Router) {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.showNotifications &&
      changes.showNotifications.currentValue !== changes.showNotifications.previousValue
    ) {
      this.showNotifications = changes.showNotifications.currentValue;
    }
    if (changes.notifications && changes.notifications.currentValue !== changes.notifications.previousValue) {
      this.notifications = changes.notifications.currentValue;
    }
  }

  hideNotifications() {
    this.handleCloseClick.emit();
  }

  onClick(url: string): void {
    if (url.length > 0) {
      this.router.navigateByUrl(url);
      if (this.router.url.match("/campaigns/edit/")) {
        setTimeout(() => {
          window.location.reload();
        });
      }
      this.handleNotificationClick.emit(this.notificationType);
    }
  }

  onScroll() {
    this.handleScroll.emit();
  }

  onScrollUp() {
    this.handleScrollUp.emit();
  }
}
