<app-dialog
  class="error-dialog"
  accentColor="error"
  icon="info"
  [title]="data.title"
  [message]="data.messageLabel"
  primaryActionLabel="Close"
  (primaryClick)="onCancel()"
  [secondaryActionLabel]="data.actionButton?.title"
  (secondaryClick)="data.actionButton && onCancel(data.actionButton.returnValue)"
>
  <ul class="m-0 p-0 display-initial" *ngIf="data.errorMessages?.length > 0">
    <li class="ml-5 my-2" *ngFor="let error of data.errorMessages">
      <div *ngIf="data.showFieldValueOnly">{{ error?.field }}</div>
      <div *ngIf="!data.showFieldValueOnly">{{ error?.message }}</div>
    </li>
  </ul>
</app-dialog>
