import { Observable, Subject } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { AuthorizationService } from "./svc/authorization.service";
import { tap, filter, map } from "rxjs/operators";
import { hex } from "./common/utils";

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
  private authorization_header: string;
  private error$ = new Subject<{ status: number }>();
  private exempted = ["/oauth/token"];
  private exempted_domains = [
    "https://async-reporting-non-prod.s3.eu-west-2.amazonaws.com",
    "https://plato-async-reporting-prod.s3.eu-west-2.amazonaws.com",
  ];
  private parentLength = 16;
  private traceLength = 32;

  constructor(authorization: AuthorizationService) {
    authorization.accessToken$
      .pipe(filter(Boolean))
      .pipe(map((token) => `Bearer ${token}`))
      .subscribe((token) => (this.authorization_header = token));
    authorization.accessToken$
      .pipe(
        map(Boolean),
        filter((present) => !present)
      )
      .subscribe(() => (this.authorization_header = undefined));
    this.error$.pipe(filter((error) => error.status === 401)).subscribe(() => authorization.logout());
  }

  private transform(request: HttpRequest<any>) {
    if (this.authorization_header == undefined) return request;
    if (this.exempted.some((suffix) => request.url.endsWith(suffix))) return request;
    if (this.exempted_domains.some((domain) => request.url.startsWith(domain))) return request;
    const parentId = hex(this.parentLength);
    const traceId = hex(this.traceLength);
    return request.clone({
      setHeaders: { Authorization: this.authorization_header, traceparent: "00-" + traceId + "-" + parentId + "-01" },
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.transform(request)).pipe(
      tap(
        () => {},
        (error) => this.error$.next(error)
      )
    );
  }
}
