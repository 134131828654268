import { CommonModule } from "@angular/common";
import { PopoverModule } from "ngx-bootstrap/popover";
import { Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";
import { NgClickOutsideDirective } from "ng-click-outside2";

@Component({
  standalone: true,
  selector: "app-delete-all-values-popup",
  templateUrl: "./delete-all-values-popup.component.html",
  styleUrls: ["./delete-all-values-popup.component.scss"],
  imports: [CommonModule, PopoverModule, NgClickOutsideDirective],
  encapsulation: ViewEncapsulation.None,
})
export class DeleteAllValuesPopupComponent {
  @Input() disableCondition: boolean = false;
  @Output() isAffirmative: EventEmitter<boolean> = new EventEmitter();
  isOpen = false;

  constructor() {}
}
