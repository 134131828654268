import { Injectable } from "@angular/core";
import { NativeDateAdapter } from "@angular/material/core";
import { INDEX_OF_MONDAY_IN_WEEK } from "./constants";

/** Adapts the native JS Date for use with cdk-based components that work with dates. */
@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
  /** Sets first day of week to Monday **/
  getFirstDayOfWeek(): number {
    return INDEX_OF_MONDAY_IN_WEEK;
  }

  format(date: Date, displayFormat: Object): string {
    if (displayFormat === "input") {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      let strDay = day + "";
      let strMonth = month + "";
      if (day <= 9) strDay = "0" + day;

      if (month <= 9) strMonth = "0" + month;

      return `${strDay}-${strMonth}-${year}`;
    }

    return date.toDateString();
  }
}
