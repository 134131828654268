import { Component, OnInit } from "@angular/core";
import { AuthorizationService } from "../../svc/authorization.service";
import { User } from "../../models/domain/user";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  user: User;
  errored = false;

  constructor(private authorization: AuthorizationService) {}

  ngOnInit() {
    setTimeout(() => {
      console.log("Login again");
      this.authorization.loginAuth0();
    }, 5000);
  }
}
