<div class="cancel-booking-dialog">
  <mat-dialog-content>
    <h4 mat-dialog-title>
      <span class="media-type-template">
        <img
          *ngIf="data.type === 'FRAMEBUY' || data.type === 'NETWORK'"
          class=""
          src="../../../../../assets/images/frame.svg"
          alt="Frames"
        />
        <img *ngIf="data.type === 'PACK'" class="" src="../../../../../assets/images/pack.svg" alt="Pack" />
        <img
          *ngIf="data.type === 'CONTAINER'"
          class=""
          src="../../../../../assets/images/container.svg"
          alt="Container"
        />
        <img
          *ngIf="data.type === 'FREE_FORM_CONTAINER'"
          class=""
          src="../../../../../assets/images/free-form-container.svg"
          alt="Free Form Container"
        />
        <span class="value-media {{ data.type.toLowerCase() }}" *ngIf="data.valueMedia">
          <img src="../../../../../assets/images/value-media.png" alt="value media" />
        </span>
      </span>
      Cancellation<span *ngIf="data.orderId"
        ><b> (</b> <span class="order-id">Order ID: {{ data.orderId }}</span
        ><b>)</b></span
      >
      <img
        (click)="close()"
        src="../../../../../assets/images/close-round-blue.svg"
        class="img-25 float-right cursor-pointer close-icon-button"
        alt="close"
      />
    </h4>
    <div class="cancel-booking-details">
      <div class="m-b-5">{{ data.formatName }} - {{ data.mediaOwnerName }} - {{ data.frameEnvironmentName }}</div>
      <div>Schedule: {{ data.scheduleStartDate }} - {{ data.scheduleEndDate }}, Qty: {{ data.quantity }}</div>
      <form [formGroup]="form">
        <div class="form-group">
          <ng-select
            #resolutionNgSelect
            name="resolution"
            [items]="resolutionTypes"
            bindLabel="name"
            bindValue="name"
            placeholder=" "
            class="form-control ng-select-single"
            data-cy="cancellation-resolution"
            formControlName="resolution"
            (change)="onResolutionTypeChange($event)"
            [ngClass]="{ 'ng-selected-values': form.get('resolution').value?.length > 0 }"
          >
          </ng-select>
          <label> Resolution<span class="error-msg">*</span> </label>
        </div>
        <div class="form-group" data-cy="amount">
          <input
            type="number"
            min="0"
            formControlName="amount"
            placeholder=" "
            (keypress)="customInputNumber($event)"
            class="form-control"
          />
          <label>
            Amount <span>{{ this.data.isInternationalCampaign ? this.data.currency : currencySymbol + "." }}</span
            ><span class="error-msg">*</span>
          </label>
        </div>
      </form>
    </div>

    <mat-dialog-actions align="center">
      <div>
        <button
          data-cy="dialog-primary-btn"
          data-test-id="confirmation/unsaved-changes-msg-yes-btn"
          class="primary-btn"
          (click)="onSubmit()"
          [disabled]="form.invalid"
        >
          Submit
        </button>
        <button
          data-cy="dialog-secondary-btn"
          data-test-id="confirmation/unsaved-changes-msg-no-btn"
          class="secondary-btn"
          (click)="close()"
        >
          Cancel
        </button>
      </div>
    </mat-dialog-actions>
  </mat-dialog-content>
</div>
