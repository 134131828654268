import { MatDialogRef } from "@angular/material/dialog";
import { Inject } from "@angular/core";
import { ChangeDetectionStrategy } from "@angular/core";
import { Component, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "error-dialog",
  templateUrl: "./error.component.html",
  styleUrls: ["./error.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailedErrorDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<DetailedErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onCancel(value?: any) {
    this.dialogRef.close(value);
  }
}
