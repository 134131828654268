import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { InputComponent } from "./input/input.component";
import { SelectComponent } from "./select/select.component";
import { FormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatFormFieldModule } from "@angular/material/form-field";

@NgModule({
  declarations: [InputComponent, SelectComponent],
  imports: [FormsModule, MatInputModule, MatFormFieldModule, MatSelectModule],
  exports: [InputComponent, SelectComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class ComponentsModule {}
