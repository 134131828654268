import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from "@angular/core";
import { MatDialogModule } from "@angular/material/dialog";
import { SuccessDialogComponent } from "./success-dialog/success-dialog.component";
import { ErrorDialogComponent } from "./error-dialog/error-dialog.component";
import { WarningDialogComponent } from "./warning-dialog/warning-dialog.component";
import { MatInputModule } from "@angular/material/input";
import { DialogComponent } from "./dialog.component";
import { MatIconModule } from "@angular/material/icon";
import { CommonModule } from "@angular/common";
import { ConfirmationDialogComponent } from "./confirmation-dialog/confirmation-dialog.component";
import { DetailedErrorDialogComponent } from "../plato-alert/error.component";

@NgModule({
  imports: [CommonModule, MatDialogModule, MatInputModule, MatIconModule],
  declarations: [
    SuccessDialogComponent,
    ConfirmationDialogComponent,
    ErrorDialogComponent,
    WarningDialogComponent,
    DialogComponent,
    DetailedErrorDialogComponent,
  ],
  exports: [
    SuccessDialogComponent,
    ConfirmationDialogComponent,
    ErrorDialogComponent,
    WarningDialogComponent,
    DialogComponent,
    DetailedErrorDialogComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class DialogModule {}
