import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpResponse,
} from "@angular/common/http";
import { Observable, of } from "rxjs";
import { tap, windowWhen } from "rxjs/operators";
import { Injectable } from "@angular/core";

@Injectable()
export class ScheduledMaintenanceInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        () => {},
        (error) => this.handleError(error)
      )
    );
  }

  private handleError(responseError: HttpErrorResponse) {
    if (
      responseError.status === 503 &&
      responseError.error != null &&
      responseError.error.status === "MAINTENANCE_MODE"
    ) {
      this.reloadPage();
    }
  }

  reloadPage() {
    window.location.reload();
  }
}
