import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";

@Component({
  standalone: true,
  selector: "app-spinner",
  templateUrl: "./spinner.component.html",
  styleUrls: ["./spinner.component.scss"],
  imports: [CommonModule],
})
export class SpinnerComponent {
  @Input() display: boolean;
  @Input() type: "primary" | "canvas";
  @Input() small: boolean;
}
