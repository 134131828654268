import * as uk from "./uk";
import * as us from "./us";
import * as ca from "./ca";
import * as ir from "./ir";
import { environment } from "src/environments/environment";

const regionalConfigurations = {
  UK: uk.config,
  CA: ca.config,
  IE: ir.config,
  US: us.config,
};

export function getConfigForRegion(region: string) {
  const regionalConfig = regionalConfigurations[region] || regionalConfigurations[environment.defaultRegion];
  const baseUrl = regionalConfig.baseUrl || environment.baseUrl;
  return {
    ...getCommonConfig(baseUrl),
    ...regionalConfig,
    ...environment,
  };
}

export function getCommonConfig(baseUrl: string) {
  return {
    baseUrl,
    apiUrl: baseUrl + "/api",
    aptApiUrl: baseUrl + "/api/audience-planning-tool/",
    aatApiUrl: baseUrl + "/api/audience-analysis-tool/",
    alertAndNotificationService: {
      url: baseUrl + "/api",
    },
    featureToggle: {
      url: baseUrl + "/featuretoggle",
    },
  };
}
