import { Component, OnInit, Inject, ViewEncapsulation } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AssociatedFrameDetails, POIDetails } from "../map-view/models/POIPopupData";

@Component({
  selector: "app-associated-frames-list",
  templateUrl: "./associated-frames-list.component.html",
  styleUrls: ["./associated-frames-list.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AssociatedFramesListComponent implements OnInit {
  frames: AssociatedFrameDetails[] = [];
  poiDetails: POIDetails;
  frameColumns: any[];

  constructor(
    public dialogRef: MatDialogRef<AssociatedFramesListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.frames = this.data.frameDetails;
    this.poiDetails = this.data.poiDetails;
    this.frameColumns = [
      { name: "Frame Id", width: 240, prop: "frameId" },
      { name: "Prohibition Type", width: 130, prop: "prohibitionType" },
      { name: "Prohibition Status", width: 150, prop: "prohibitionStatus" },
      { name: "Distance from the POI (Feet)", width: 200, prop: "distanceFromPOI" },
      { name: "Address", width: 150, prop: "address" },
      { name: "Latitude", width: 150, prop: "lat" },
      { name: "Longitude", width: 150, prop: "long" },
    ];
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
