import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from "@angular/common/http";
import { tap } from "rxjs/operators";
import { Observable } from "rxjs";

@Injectable()
export class HttpDateParserInterceptor implements HttpInterceptor {
  // Migrated from AngularJS https://raw.githubusercontent.com/Ins87/angular-date-interceptor/master/src/angular-date-interceptor.js
  iso8601 = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z|(\+\d{4}))?$/;

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const patterns = [/\/api\/inventory\/campaigns/gi, /\/inventory\/inChargePeriods/gi];
    const matches = patterns.some((pattern) => request.url.search(pattern) !== -1);
    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        // TODO: Do not perform side effects
        if (!matches || !(event instanceof HttpResponse)) return;
        this.convertToDate(event.body);
      })
    );
  }

  private convertToDate(body) {
    if (body === null || body === undefined) return body;
    if (typeof body !== "object") return body;

    for (const key of Object.keys(body)) {
      const value = body[key];
      if (this.isIso8601(value)) {
        body[key] = new Date(Date.parse(value));
      } else if (typeof value === "object") {
        this.convertToDate(value);
      }
    }
  }

  private isIso8601(value) {
    if (value == null) return false;
    return this.iso8601.test(value);
  }
}
