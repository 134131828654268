<app-input #testForm appearance="outline" label="{{ label }}">
  <mat-select
    [name]="name"
    [formControlName]="name"
    [(valueChange)]="valueChange"
    [(selectionChange)]="selectionChange"
  >
    <ng-content #ngContent></ng-content>
  </mat-select>
</app-input>
