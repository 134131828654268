<app-dialog
  accentColor="success"
  icon="check_circle"
  title="Success"
  [message]="message"
  [secondaryMessage]="secondaryMessage"
  [primaryActionLabel]="primaryActionLabel"
  (primaryClick)="close()"
>
</app-dialog>
