import { Injectable } from "@angular/core";
import { saveAs } from "file-saver";

export enum FileTypes {
  XLSX = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  CSV = "text/CSV",
  JSON = "application/json",
  PDF = "application/pdf",
  GENERIC = "application/octet-stream",
}

@Injectable({
  providedIn: "root",
})
export class FileSaver {
  saveFileToDisk(
    // eslint-disable-next-line
    fileData: any,
    fileName: string,
    fileType: FileTypes = FileTypes.GENERIC
  ) {
    return saveAs(new Blob([fileData], { type: fileType.toString() }), fileName);
  }
}

export function getFileName(file: File): string {
  return file.name.split(".")[0];
}
