<span
  *ngIf="display"
  class="block-spinner"
  [ngClass]="{
    primary: type === 'transparent',
    canvas: type === 'canvas'
  }"
>
  <div
    class="table-loader"
    [ngClass]="{
      primary: type === 'transparent',
      canvas: type === 'canvas'
    }"
  ></div>
</span>
