import { Component, Input } from "@angular/core";

@Component({
  selector: "app-editable-text-cell",
  templateUrl: "./editable-text-cell.component.html",
  styleUrls: ["./editable-text-cell.component.scss"],
})
export class EditableTextCellComponent {
  isBeingEdited = false;
  @Input() readonly = false;
  toggleEditingMode($event: Event) {
    if (this.readonly) {
      return;
    }
    this.isBeingEdited = !this.isBeingEdited;
    $event.stopPropagation();
  }
}
