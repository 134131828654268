import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-checkbox",
  templateUrl: "./checkbox.component.html",
  styleUrls: ["./checkbox.component.scss"],
})
export class CheckBoxComponent {
  @Input() checked: boolean;
  @Input() disabled: boolean;
  @Output() checkedChange = new EventEmitter<boolean>();

  toggleChecked(checked: boolean) {
    this.checked = checked;
    this.checkedChange.emit(this.checked);
  }
}
