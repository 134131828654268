export interface AuthorizationInfo {
  access_token: string;
  expires_in: number;
  logged_in: boolean;
  refresh_token: string;
}

export const claimKeys = {
  user_name: "user_name",
  authorities: "authorities",
  namespace: "https://plato.talonoutdoor.com",
};
