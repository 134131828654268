import { IdentifiableResource } from "../../models/generic/models";
import { ApiService } from "../api/api.service";
import { Observable } from "rxjs";
import { Page } from "../../models/domain/Page";

export class ResourceService<T extends IdentifiableResource> {
  constructor(protected apiCall: ApiService, protected endpoint: string) {}

  create(object: any): Observable<T> {
    return this.apiCall.post<T>(this.endpoint, object);
  }

  getAll(): Observable<T[]> {
    return this.apiCall.get<T[]>(this.endpoint);
  }

  getAllPaginated(page?: number, size?: number, sortByAttributeName?: string): Observable<Page<T>> {
    return this.apiCall.get<Page<T>>(this.endpoint + "?" + this.getPaginationParams(page, size, sortByAttributeName));
  }

  getById(id: string): Observable<T> {
    return this.apiCall.get<T>(this.endpoint + "/" + id);
  }

  update(id: string, objectUpdated: T): Observable<any> {
    return this.apiCall.put<T>(this.endpoint + "/" + id, objectUpdated);
  }

  deleteById(id: string): Observable<any> {
    return this.apiCall.delete<T>(this.endpoint + "/" + id);
  }

  protected getPaginationParams(page = 0, size = 500, sortBy?: string): string {
    let res = "page=" + page.toString() + "&size=" + size.toString();
    if (sortBy) res += "&sort=" + sortBy;
    return res;
  }
}
