import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-collapsable-card",
  templateUrl: "./collapsable-card.component.html",
  styleUrls: ["./collapsable-card.component.scss"],
})
export class CollapsableCardComponent implements OnInit {
  @Input() title: string;
  @Input() elevated?: boolean;
  @Input() expanded?: boolean;
  @Input() disabled?: boolean;
  public isExpanded: boolean;
  constructor() {}

  ngOnInit() {
    this.isExpanded = this.expanded;
  }

  public toggleExpansionPanel() {
    this.isExpanded = !this.isExpanded;
  }
}
