import { CURRENCIES_SYMBOL } from "src/app/core/common/currency-constants";

export const campaignConfig = {
  campaignProhibitions: [],
  costTypeDisplayConfig: {
    content: [
      {
        code: "CREATIVE_SERVICES",
        order: 1,
      },
      {
        code: "CREATIVE_TESTING",
        order: 2,
      },
      {
        code: "ADA",
        order: 3,
      },
      {
        code: "ASBOF",
        order: 4,
      },
    ],
  },
  showFrameImages: false,
  setCampaignAudienceToPrimaryAudience: true,
  isRouteVersionMandatory: true,
  isDateFormatDDMMYYYY: true,
  hfss_disabled: false,
  loadInchargeStartDates: true,
  displayHFSS: true,
  displayProhibition: false,
  displayPrimaryAudience: true,
  displayTargetAudience: false,
  displayRouteVersion: true,
  updateBillingUnitWithMOVal: true,
  displayValueMediaAndPacks: true,
  currencySymbol: CURRENCIES_SYMBOL.UK,
  disableCursor: false,
  displayFeePercentageCol: false,
  downloadCampaignBookings: false,
  exportCampaign: false,
  allowMediaUpload: false,
  currencyList: ["GBP"],
  addExchangeMultiplierControl: true,
  addFeeCalculationTypeControl: false,
  allowMarginMarkupToEdit: false,
  addPrincipalBeneficiaryControl: false,
  customAgencySACValue: true,
  displayClientOrderNumber: false,
  displayAgencyCode: false,
  displayCustomerReferenceNumber: true,
  showPlacementReference: false,
  showCustomerReference: false,
  hideExternalProductionCostAndPostingCharges: false,
  disableTvRegionForNationalReach: false,
  isTvRegionMandatoryInFFCGeography: false,
  displayCancellationPolicyPop: false,
  closeCampaignSaveConfirmation: false,
  disableSave: true,
  shouldDisableLineAmendment: true,
  hideCustomerReference: false,
  setMarginLabel: true,
  showPrepareOption: true,
  showAvailsAction: true,
  displayAmendment: true,
  displayExternalProdCost: true,
  displayBillingUnit: false,
  loadGeoLocationForStateAndCountry: false,
  updateDMADropDown: false,
  setPrimaryAudience: false,
  setPrimaryAudienceAndKPI: true,
  displayMediaOwnerFrameRef: false,
  ukSpecificCampaignBudgetComp: true,
  countryCodeForFinanceIdMapping: "UK",
  showCityFieldInFFC: false,
  enableSupplierContract: true,
  displayAdaAudience: true,
  adaAudienceCountryCode: "uk",
  allowCascadingPriceChange: true,
};
