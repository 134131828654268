import { Pipe, PipeTransform } from "@angular/core";
import moment from "moment";
import momentTimeZone from "moment-timezone";
import { earliestDateTZRegion, latestDateTZRegion } from "./constants/const";
import { ConfigurationService } from "../svc/configuration-service";

const environment = new ConfigurationService().getConfig();

@Pipe({ name: "platoDatePipe" })
export class PlatoDatePipe implements PipeTransform {
  transform(date: Date | string, yearFormat?: string): string {
    let dateFormat: string;
    dateFormat = environment.Formats.dateFormat;
    if (yearFormat) {
      dateFormat = dateFormat.replace("YYYY", yearFormat);
    }
    return moment(date).format(dateFormat);
  }
}

@Pipe({
  name: "platoDateTimePipe",
})
export class PlatoDateTimePipe implements PipeTransform {
  transform(date: Date | string, yearFormat = "YYYY"): string {
    if (!date) {
      return "";
    }
    let dateFormat: string;
    dateFormat = environment.Formats.dateFormat;
    dateFormat = dateFormat.replace("YYYY", `${yearFormat}, hh:mm A`);
    return moment(date).format(dateFormat);
  }
}

@Pipe({ name: "platoDatePipeUTC" })
export class PlatoDatePipeUTC implements PipeTransform {
  transform(date: Date | string, yearFormat?: string): string {
    let dateFormat: string;
    dateFormat = environment.Formats.dateFormat;
    if (yearFormat) {
      dateFormat = dateFormat.replace("YYYY", yearFormat);
    }
    return moment(date).utc().format(dateFormat);
  }
}

@Pipe({ name: "platoEarliestTimezoneDatePipe" })
export class PlatoEarliestTimezoneDatePipe implements PipeTransform {
  transform(date: Date | string, yearFormat?: string): string {
    let dateFormat: string;
    dateFormat = environment.Formats.dateFormat;
    if (yearFormat) {
      dateFormat = dateFormat.replace("YYYY", yearFormat);
    }
    return momentTimeZone.tz(date, earliestDateTZRegion).format(dateFormat);
  }
}

@Pipe({ name: "platoLatestTimezoneDatePipe" })
export class PlatoLatestTimezoneDatePipe implements PipeTransform {
  transform(date: Date | string, yearFormat?: string): string {
    let dateFormat: string;
    dateFormat = environment.Formats.dateFormat;
    if (yearFormat) {
      dateFormat = dateFormat.replace("YYYY", yearFormat);
    }
    return momentTimeZone.tz(date, latestDateTZRegion).format(dateFormat);
  }
}
