<div class="datatable-wrapper" [ngClass]="{ 'disable-column-header': rows.length === 0 }">
  <span class="group-title" *ngIf="selectedColumns.length === 0 && allowDragDrop"
    >Drag column name to set row group</span
  >
  <div class="group-wrapper" *ngIf="allowDragDrop">
    <span class="drag-icon"></span>
    <div class="group-items" [dragula]="bag" id="groupArea" (click)="removeGroupedColumn($event)" #groupArea></div>
    <div class="remove-all-groups" (click)="removeAllGroups()" *ngIf="selectedColumns.length !== 0">
      <img src="../../../../assets/images/refresh-button-blue.svg" class="img-14" alt="refresh" />Reset All Group(s)
    </div>
  </div>
  <ngx-datatable
    datatable-resize-watcher
    class="material"
    [rows]="rows"
    [columns]="outerColumns"
    [rowHeight]="rowHeight"
    [headerHeight]="headerHeight"
    [footerHeight]="0"
    [scrollbarV]="true"
    [selectionType]="selectionType"
    [selectAllRowsOnPage]="false"
    [selected]="selected"
    [scrollbarH]="true"
    [virtualization]="virtualization"
    [loadingIndicator]="loading"
    [rowIdentity]="identity"
    [externalSorting]="externalSorting"
    (select)="onRowSelect($event)"
    (scroll)="onScroll($event)"
    (sort)="onSort($event)"
    [messages]="messages"
    [columnMode]="'force'"
  >
  </ngx-datatable>

  <ng-template #header_template let-column="column" let-sort="sortFn" let-sortDir="sortDir">
    <div [dragula]="bag" id="dragArea" #dragArea *ngIf="!column.customHeaderTemplate">
      <span
        class="datatable-header-cell-wrapper"
        (click)="sort($event, sortDir, sortFn)"
        [attr.data-name]="column.name"
      >
        <span class="datatable-header-cell-label" [ngClass]="{ draggable: column.draggable }">{{ column.name }}</span>
        <span [attr.data-column]="column.column" class="remove-group"></span>
      </span>
    </div>
    <div [dragula]="bag" id="dragArea" #dragArea *ngIf="column.customHeaderTemplate">
      <span
        class="datatable-header-cell-wrapper"
        (click)="sort($event, sortDir, sortFn)"
        [attr.data-name]="column.name"
      >
        <span [ngClass]="{ draggable: column.draggable }">
          <ng-container
            *ngTemplateOutlet="
              column.customHeaderTemplate;
              context: { column: column, sortDir: sortDir, sortFn: sortFn }
            "
          >
          </ng-container>
        </span>
        <span [attr.data-column]="column.column" class="remove-group"></span>
      </span>
    </div>
  </ng-template>

  <ng-template #cell_template let-rowIndex="rowIndex" let-definition="column" let-item="row" let-value="value">
    <ng-container *ngIf="item.type === 'group'">
      <a
        *ngIf="definition.column === item.column"
        class="blue-link"
        [class.datatable-icon-right]="!item.expanded"
        [class.datatable-icon-down]="item.expanded"
        title="Expand/Collapse Row"
        (click)="toggleGroup(item, item.expanded); onShowContainer(item)"
        [ngClass]="{ 'highlight-group-text': definition.column === item.column && item.highlightGroup }"
        >{{ item.name }} ({{ item.total }})
        <span class="group-detail" *ngIf="item?.detail !== null && item?.detail !== undefined ? true : false">
          ({{ item.detail }})
        </span>
      </a>
      <ng-container *ngIf="definition.column !== item.column && definition.originalCellTemplate != undefined">
        <ng-container
          *ngTemplateOutlet="definition.originalCellTemplate; context: { row: item, value: value, rowIndex: rowIndex }"
        >
          <div>{{ value }}</div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="definition.column !== item.column">
        <div>{{ value }}</div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="item.type === 'row'">
      <ng-container *ngIf="definition.originalCellTemplate != undefined">
        <ng-container
          *ngTemplateOutlet="definition.originalCellTemplate; context: { row: item, value: value, rowIndex: rowIndex }"
        >
          <div [ngClass]="{ 'no-value': !value }">{{ !value ? "-" : value }}</div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="definition.originalCellTemplate == undefined">
        <div [ngClass]="{ 'no-value': !value }">{{ !value ? "-" : value }}</div>
      </ng-container>
    </ng-container>
  </ng-template>

  <ng-template #selection_header_template>
    <mat-checkbox
      [disabled]="!isSelectAllEnabled"
      type="checkbox"
      [checked]="isAllSelected"
      (change)="onSelectAllChanged($event)"
      color="primary"
    >
    </mat-checkbox>
  </ng-template>

  <ng-template
    #selection_cell_template
    let-item="row"
    let-value="value"
    let-checked="isSelected"
    let-onChange="onCheckboxChangeFn"
  >
    <mat-checkbox
      type="checkbox"
      [checked]="checked"
      [indeterminate]="indeterminate(item)"
      (change)="onChange($event)"
      color="primary"
    >
    </mat-checkbox>
  </ng-template>

  <div
    class="splitter-bound"
    [ngClass]="{ 'top-b': !edge?.top, 'bottom-b': !edge?.bottom, 'left-b': !edge?.left, 'right-b': !edge?.right }"
    [style.top.px]="boundTopPosition"
    *ngIf="showSplitter"
    #myBounds
  >
    <div
      ngDraggable
      class="drag-block"
      [bounds]="myBounds"
      [inBounds]="inBounds"
      [position]="position"
      [preventDefaultEvent]="true"
      (edge)="checkEdge($event)"
      (movingOffset)="onMoving($event)"
      (endOffset)="onMoveEnd($event)"
      *ngIf="rows.length > 0"
    >
      <div class="icon-wrapper">
        <span class="icon-left"></span>
        <span class="icon-right"></span>
      </div>
    </div>
  </div>
</div>
