<span
  *ngIf="display"
  class="block-spinner"
  [ngClass]="{
    primary: type === 'primary',
    canvas: type === 'canvas',
    small: small
  }"
>
  <div
    class="lds-dual-ring"
    [ngClass]="{
      primary: type === 'primary',
      canvas: type === 'canvas',
      small: small
    }"
  ></div>
</span>
