<div class="popover-clear">
  <img
    src="../../../../../assets/images/close-round.svg"
    [ngClass]="{ 'cursor-disabled': disableCondition }"
    class="img-16 clear-button"
    [popover]="myPopover"
    clickOutsideEnabled
    placement="bottom"
    #pop="bs-popover"
  />
  <ng-template #myPopover class="popover-dele-con">
    <div class="popover-dele-con">
      <p>Delete all values?</p>
      <ul class="list-inline">
        <li class="list-inline-item">
          <a class="yes-button" (click)="isAffirmative.emit(true); pop.hide()">Yes</a>
        </li>
        <li class="list-inline-item">|</li>
        <li class="list-inline-item">
          <a class="no-button" (click)="isAffirmative.emit(false); pop.hide()">No</a>
        </li>
      </ul>
    </div>
  </ng-template>
</div>
