export enum AATColumn {
  audienceName,
  totalFrames,
  reach,
  averageFrequency,
  impacts,
  cover,
  grossRatingPoints,
  ragStatus,
  audienceId,
  reachPercentage,
  trp,
  allPersonsImpressions,
}
