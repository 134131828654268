import { Pipe, PipeTransform } from "@angular/core";
import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { CustomValidator } from "../../common/validators/form-validators/custom-validator";

@Pipe({
  name: "isRequired",
})
export class FormControlRequiredPipe implements PipeTransform {
  transform(validatorFn: ValidatorFn): boolean {
    if (!validatorFn) {
      return false;
    }

    const validator = validatorFn({} as AbstractControl);
    return (validator && validator.required) === true;
  }
}

@Pipe({
  name: "isNotEntered",
})
export class IsNotEnteredPipe implements PipeTransform {
  transform(errors: ValidationErrors): boolean {
    if (!errors) {
      return false;
    }
    return !!errors?.FieldNotEntered;
  }
}
