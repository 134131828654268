import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse,
  HttpClient,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { ConfigurationService } from "src/app/core/svc/configuration-service";
import { catchError } from "rxjs/operators";
const environment = new ConfigurationService().getConfig();
@Injectable()
export class HttpMessageExternaliser implements HttpInterceptor {
  errorMappings: Map<string, string>;
  errorMappingConfig$: Observable<Map<string, string>>;
  constructor(private http: HttpClient) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.errorMappingConfig$) {
      this.errorMappingConfig$ = this.http.get<Map<string, string>>(
        "assets/json/error/" + environment.region.toLowerCase() + ".json"
      );
      this.errorMappingConfig$.subscribe((config) => {
        this.errorMappings = config;
      });
    }
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        error = this.externalizeErrorMessage(error);
        return throwError(error);
      })
    );
  }

  private externalizeErrorMessage(errorResponse: HttpErrorResponse): HttpErrorResponse {
    if (!errorResponse) {
      return errorResponse;
    }
    if (this.errorMappings[errorResponse.error?.code]) {
      errorResponse.error.msg = this.errorMappings[errorResponse.error?.code];
    }
    if (errorResponse.error?.subErrors) {
      errorResponse.error.subErrors = errorResponse.error.subErrors
        .map((item) => {
          if (this.errorMappings[item.errorCode]) {
            item.message = this.errorMappings[item.errorCode];
          }
          return item;
        })
        .map(this.replaceMessageTokens);
    }
    return errorResponse;
  }

  private replaceMessageTokens(error: any) {
    const messageTokenList = error.messageTokenValueDetailsList;
    if (messageTokenList === null || messageTokenList === undefined) {
      return error;
    }
    messageTokenList.forEach((item) => {
      const token = "{" + item.tokenName + "}";
      error.message = error.message.replace(new RegExp(token, "g"), item.value);
    });

    return error;
  }
}
