import { Injectable } from "@angular/core";
import { ApiService } from "../../api/api.service";
import { ResourceService } from "../resource-service.service";
import { Observable, of } from "rxjs";
import { ConfigurationService } from "src/app/core/svc/configuration-service";
const environment = new ConfigurationService().getConfig();
import { IdentifiableResource } from "src/app/core/models/generic/models";
import { HttpClient } from "@angular/common/http";
import { switchMap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class FeatureToggleService extends ResourceService<IdentifiableResource> {
  private showDebugMessage = false;

  private features = {};

  constructor(apiCall: ApiService, private httpClient: HttpClient) {
    super(apiCall, "/api/custom/features");
    this.getAllFeatures();
    setTimeout(() => {
      this.isFeatureEnabled("UI_SHOW_DEBUG_ERROR_MESSAGE").subscribe((result) => {
        this.showDebugMessage = result;
      });
    }, 5000);
  }

  isFeatureEnabled(featureName: string, cache: boolean = true): Observable<boolean> {
    // return of(true);

    if (cache && this.features[featureName] !== undefined) {
      return of(this.features[featureName]);
    }

    const url = this.endpoint + "/" + featureName + "/";
    return this.httpClient.request<boolean>("POST", this.constructFullyQualifiedURL(url)).pipe(
      switchMap((response) => {
        this.features[featureName] = response;
        return of(this.features[featureName]);
      })
    );
  }

  getAllFeatures(): Observable<any> {
    const url = `/api/client/features`;
    return this.httpClient.request<any>("GET", this.constructFullyQualifiedURL(url)).pipe(
      switchMap((response) => {
        response.features.map((feature) => {
          this.features[feature.name] = feature.enabled;
        });
        return of(response);
      })
    );
  }

  shouldShowDebugMessage(): boolean {
    return this.showDebugMessage;
  }

  private constructFullyQualifiedURL(endpoint: string): string {
    return environment.featureToggle.url + endpoint;
  }
}
