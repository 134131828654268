import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "joinList",
})
export class JoinListPipe implements PipeTransform {
  transform(value: Array<any>, args?: any): any {
    const joinToken = args || ",";
    return (value || []).join(joinToken);
  }
}
