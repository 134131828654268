import { Component, ViewEncapsulation } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-poi-search-dialog",
  templateUrl: "./poi-search.dialog.html",
  styleUrls: ["./poi-search.dialog.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class PoiSearchDialogComponent {
  constructor(public dialogRef: MatDialogRef<PoiSearchDialogComponent>) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
