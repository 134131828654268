import { Component, OnInit, Inject, EventEmitter, Output, Input } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-success-dialog",
  templateUrl: "./success-dialog.component.html",
  styleUrls: ["./success-dialog.component.scss"],
})
export class SuccessDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<SuccessDialogComponent>) {
    if (this.data) {
      this.message = this.data.message;
      this.secondaryMessage = this.data.secondaryMessage;
    }
  }
  @Output() closed = new EventEmitter<boolean>();

  @Input() message: string;

  @Input() secondaryMessage: string;

  @Input() primaryActionLabel: string = "OK";

  close() {
    this.closed.emit(true);
    this.dialogRef.close();
  }
}
