import { Directive, Output, EventEmitter, HostListener } from "@angular/core";
import { _window } from "../utils";

@Directive({
  selector: "[scrollTop]",
})
export class ScrollTopDirective {
  @Output() scrollPosition: EventEmitter<number> = new EventEmitter<number>();
  window = _window();

  @HostListener("scroll", ["$event"]) private onScroll($event: any): void {
    this.scrollPosition.emit($event.target.scrollTop);
  }
}
