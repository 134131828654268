import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";

@Component({
  standalone: true,
  selector: "tile-spinner",
  templateUrl: "./tile-spinner.component.html",
  styleUrls: ["./tile-spinner.component.scss"],
  imports: [CommonModule],
})
export class TileSpinnerComponent {
  display = false;
  @Input() type: "transparent" | "canvas";

  constructor() {}

  startDisplay() {
    this.display = true;
  }
  stopDisplay() {
    this.display = false;
  }
}
