import {
  Component,
  OnInit,
  Input,
  Output,
  ElementRef,
  ViewChild,
  TemplateRef,
  EventEmitter,
  OnDestroy,
} from "@angular/core";
import { ColumnDefinition } from "./column-definition";
import { Subject, EMPTY } from "rxjs";
import { SortEvent } from "./sort-event";
import { DatatableComponent as NgxDatatableComponent, SelectionType } from "@swimlane/ngx-datatable";
import { Group } from "./group";
import { has, unique } from "../../common/utils";
import { Row } from "./row";
import { LineItem } from "./line-item";
import { map, distinctUntilChanged, tap } from "rxjs/operators";
import { DragulaService } from "ng2-dragula";

import { combineLatest, BehaviorSubject } from "rxjs";

@Component({
  selector: "app-datatable",
  templateUrl: "./datatable.component.html",
  styleUrls: ["./datatable.component.scss"],
})
export class DatatableComponent implements OnInit, OnDestroy {
  @Input() isSelectAllEnabled = false;
  @Input() allFrameIdsOfSearchResult: string[] = [];
  @Input() parent: DatatableComponent = undefined;
  @Input() virtualization: boolean;
  @Input() allowSorting = false;
  @Input() allowDragDrop = false;
  @Input() showSplitter = false;
  @Input() externalSorting = true;
  @Input() expandSubGroup = false;
  @Input() expandRefreshGroup = false;
  @Input() set rows(rows: LineItem[]) {
    this.groupsInitializedForExpansion = [];
    this.setRows(rows);
  }
  @Input() set columns(columns: ColumnDefinition[]) {
    this.initColumns(columns);
  }
  @Input() set allowSelection(allowSelection: boolean) {
    this._allowSelection = allowSelection;
    this.setColumns();
  }
  @Input() set selectedIds(selected: string[]) {
    this._selectedIds = unique(selected);
    this.updateSelections();
  }
  @Input() set grpColumns(columns: ColumnDefinition[]) {
    this._grpColumns$.next(columns);
  }
  @Input() loading = false;
  @Input() set identity(identity: (row: LineItem) => string) {
    this._identity = identity;
    this.updateSelections();
  }
  @Input() rowHeight = 36;
  @Input() headerHeight = 36;
  @Input() message: string;
  @Input() index?: number = undefined;
  @Input() set groupBy(groupBy: string[]) {
    const current = this._groupBy$.value;
    const next = groupBy;
    if (next.length === current.length && next.every((x, i) => current[i] === x)) return;
    this._groupBy$.next(next);
    if (!next.length) {
      this.resetGroup();
    }
  }
  private _groupBy$ = new BehaviorSubject<string[]>([]);
  @Output() load = new Subject<string>();
  @Output() group = this._groupBy$.asObservable();
  @Output() get select() {
    return this._select
      .asObservable()
      .pipe(map((ids) => unique(ids)))
      .pipe(distinctUntilChanged());
  }
  @Output() scroll = new Subject<void>();
  @Output() sort = new Subject<SortEvent>();
  @Output() isAllSelectedEvent = new EventEmitter<boolean>();
  @Output() containerPopUp = new EventEmitter<any>();
  @ViewChild(NgxDatatableComponent, { static: true }) table: NgxDatatableComponent;
  @ViewChild("header_template", { static: true }) headerTemplate: TemplateRef<any>;
  @ViewChild("cell_template", { static: true }) cellTemplate: TemplateRef<any>;
  @ViewChild("selection_cell_template", { static: true }) selectionCellTemplate: TemplateRef<any>;
  @ViewChild("selection_header_template", { static: true }) selectionHeaderTemplate: TemplateRef<any>;
  @ViewChild("groupArea") groupArea: ElementRef;
  @ViewChild("dragArea") dragArea: ElementRef;
  datatable = this;
  isAllSelected = false;
  frozenRightWidth = 0;
  frozenRightColsLen = 0;
  selected: LineItem[] = [];
  outerColumns: ColumnDefinition[] = [];
  selectedColumns: ColumnDefinition[] = [];
  suggestions: ColumnDefinition[] = [];
  bag = "GROUP_COLUMNS";

  movingOffset = { x: 0, y: 0 };
  endOffset = { x: 0, y: 0 };
  position = { x: 0, y: 0 };
  inBounds = true;
  boundTopPosition = 28;
  myBounds = true;
  edge = {
    top: true,
    bottom: true,
    left: true,
    right: true,
  };
  sortFn: any;

  groupIdentity = (group: Group<LineItem>) => group.id;

  get selectionType() {
    return this.allowSelection ? SelectionType.checkbox : undefined;
  }
  get sorting() {
    return this.allowSorting ? "single" : undefined;
  }
  get messages() {
    return { emptyMessage: this.message };
  }
  get rows() {
    return this._rows;
  }
  get allowSelection() {
    return this._allowSelection;
  }
  get selectedIds() {
    return this._selectedIds;
  }
  get identity() {
    return this.__identity;
  }

  private selectedRows = [];
  private _identity: (row: LineItem) => string = JSON.stringify;
  private __identity = (item: LineItem) => (item.type === "group" ? (<Group<LineItem>>item).id : this._identity(item));
  private _selectedIds: string[] = [];
  private _select = new Subject<string[]>();
  private _columns: ColumnDefinition[] = [];
  private _columnKeys: string[] = [];
  private selectionColumn: ColumnDefinition = {
    width: 30,
    sortable: false,
    canAutoResize: false,
    draggable: false,
    resizeable: false,
    frozenLeft: true,
  } as any;
  private _rows: LineItem[] = [];
  private _allowSelection = false;
  private _addSelectionColumn(columns: ColumnDefinition[]) {
    return !this.allowSelection
      ? columns
      : [
          {
            ...this.selectionColumn,
            cellTemplate: this.selectionCellTemplate,
            headerTemplate: this.selectionHeaderTemplate,
          },
          ...columns,
        ];
  }
  private setColumns() {
    const columns = this._columns.map((column) => ({
      ...column,
      cellTemplate: this.cellTemplate,
      headerTemplate: this.headerTemplate,
      originalCellTemplate: column.cellTemplate,
    }));
    this.outerColumns = this._addSelectionColumn(columns);
  }
  private setRows(rows: LineItem[]) {
    this._rows = rows;
    if (this.expandSubGroup) {
      (<HTMLElement>this.table.element.getElementsByClassName("datatable-body")[0]).style.minHeight = `${
        this.rows.length * 36 + 13
      }px`;
      (<HTMLElement>this.table.element.getElementsByClassName("datatable-body")[0]).style.height = `${
        this.rows.length * 36 + 13
      }px`;
    }
    this.updateSelections();
  }
  private updateSelections() {
    const selected = this.rows.filter((item) =>
      item.type === "group"
        ? (<Group<LineItem>>item).rowIdentities.every((id) => this.selectedIds.includes(id))
        : this.selectedIds.includes(this.identity(<Row>item))
    );
    this.setSelected(selected);
    if (this.selectedIds != undefined && this.selectedIds.length != this.allFrameIdsOfSearchResult.length) {
      this.isAllSelected = false;
    }
  }
  private resetGroup() {
    if (!this.groupArea) return;
    this.groupBy = [];
    const parent = this.groupArea.nativeElement;
    while (parent.firstChild) {
      (<HTMLElement>parent.firstChild).remove();
    }
  }
  private groupsInitializedForExpansion = [];
  private _grpColumns$ = new BehaviorSubject<ColumnDefinition[]>([]);
  private _groupedValues$ = new BehaviorSubject<string[]>(null);
  public refreshGroupNames: string[] = [];

  constructor(private element: ElementRef, private dragulaService: DragulaService) {
    combineLatest(this._grpColumns$, this.group)
      .pipe(map(([grpColumns, groupBy]) => grpColumns.filter((definition) => !groupBy.includes(definition.column))))
      .subscribe((grpColumns) => (this.suggestions = grpColumns));
    combineLatest(this._grpColumns$, this.group)
      .pipe(
        map(([grpColumns, groupBy]) => groupBy.map((key) => grpColumns.find((definition) => definition.column === key)))
      )
      .subscribe((grpColumns) => (this.selectedColumns = grpColumns));
    this._groupedValues$.subscribe((columns) => (this.groupBy = this._groupBy$.value.splice(0, 0).concat(columns)));
  }

  ngOnInit() {
    this.initColumnDrag();
    if (this.allowDragDrop) {
      this.boundTopPosition += 50;
    }
  }

  initColumns(columns: ColumnDefinition[], isSplitterMoved?: boolean) {
    if (!columns.length) return;
    const colNames = [];
    this.frozenRightWidth = 0;
    this.frozenRightColsLen = 0;
    for (let i = 0, colLength = columns.length; i < colLength; i++) {
      colNames.push(columns[i].column);
      if (columns[i].frozenRight) {
        this.frozenRightWidth += columns[i].width;
        this.frozenRightColsLen++;
      }
    }
    this.position.x = this.datatable.element.nativeElement.firstChild.clientWidth - this.frozenRightWidth;
    if (this._columnKeys.length && JSON.stringify(this._columnKeys) !== JSON.stringify(colNames)) {
      this.datatable.element.nativeElement.getElementsByTagName("datatable-body")[0].scrollLeft = 0;
    }
    this._columnKeys = colNames;
    this._columns = columns;
    this.setColumns();
    setTimeout(
      () => {
        if (<HTMLElement>this.datatable.element.nativeElement.getElementsByClassName("drag-block")[0]) {
          const posLeft =
            this.datatable.element.nativeElement.firstChild.clientWidth -
            this.datatable.element.nativeElement.querySelector(".datatable-body-row .datatable-row-right").offsetWidth -
            19;
          (<HTMLElement>(
            this.datatable.element.nativeElement.getElementsByClassName("drag-block")[0]
          )).style.transform = `translate(${posLeft}px, 0)`;
        }
      },
      isSplitterMoved ? 50 : 1200
    );
  }

  initColumnDrag() {
    const self = this;
    if (this.allowDragDrop) {
      this.dragulaService.createGroup(this.bag, {
        copy: (el, source) => {
          const colName = (<HTMLElement>source.firstChild.firstChild.nextSibling).dataset.column;
          const isDragging = setInterval(
            () => {
              const groupParentNode = self.groupArea.nativeElement;
              if (groupParentNode) {
                const unselectable = document.getElementsByTagName("body")[0].className.indexOf("gu-unselectable");
                if (unselectable === -1) {
                  clearInterval(isDragging);
                  const groupChildNodes = groupParentNode.childNodes;
                  const columns = [];
                  for (let i = 0, groupNodeLength = groupChildNodes.length; i < groupNodeLength; i++) {
                    columns.push((<HTMLElement>groupChildNodes[i].firstChild.nextSibling).dataset.column);
                  }
                  console.log("Groups", columns);
                  this.addToGroup(columns);
                }
              }
            },
            100,
            colName
          );
          return el.id !== "dragArea";
        },
        invalid: (el, target) => {
          const groupNodes = self.groupArea.nativeElement.childNodes;
          let isValid = false;
          for (let i = 0, groupNodeLength = groupNodes.length; i < groupNodeLength; i++) {
            if ((<HTMLElement>groupNodes[i]).dataset.name === target.textContent) {
              isValid = true;
              break;
            }
          }
          return isValid;
        },
        accepts: (_el, target, source) => {
          // To avoid dragging from groupArea to dragArea container
          return (
            target.id !== "dragArea" &&
            self.rows.length > 0 &&
            !source.parentElement.parentElement.parentElement.className.includes("datatable-row-right") &&
            source.firstElementChild.firstElementChild.className.includes("draggable")
          );
        },
      });
    }
  }

  addToGroup(columns: string[]) {
    this._groupedValues$.next(columns);
  }

  removeFromGroup(column: string) {
    this.groupBy = this._groupBy$.value.filter((key) => key !== column);
  }

  removeGroupedColumn(event) {
    if (event.target.className === "remove-group") {
      const colName = event.target.dataset.column;
      if (colName) {
        this.removeFromGroup(colName);
        event.target.parentNode.remove();
      }
    }
  }

  removeAllGroups() {
    this.addToGroup([]);
    this.resetGroup();
  }

  setSelected(selected: LineItem[]) {
    this.selected = selected.filter(() => true);
    this.selectedRows = selected.filter(() => true);
  }

  onSelect(selected: string[]) {
    this._select.next(selected);
  }

  getGroupHeight = (group?: Group<LineItem>) => {
    if (group == undefined) return 0;
    const height = group.rows.length * this.rowHeight;
    return height;
  };

  private onLineItemSelect(selected: LineItem[], identities: (item: LineItem) => string[]) {
    if (selected === undefined) {
      return;
    }
    const previous = <LineItem[]>this.selectedRows;
    const added = previous.length < selected.length;
    if (added) {
      const item = selected.find((item) => !has(item)(previous));
      const ids = identities(item);
      const next = this.selectedIds.concat(ids);
      this.onSelect(next);
    } else {
      const item = previous.find((item) => !has(item)(selected));
      const ids = identities(item);
      const next = this.selectedIds.filter((x) => !ids.includes(x));
      this.onSelect(next);
    }
  }

  onRowSelect({ selected }: { selected: LineItem[] } | any) {
    this.onLineItemSelect(selected, (item) =>
      item.type === "group" ? (<Group<LineItem>>item).rowIdentities : [this.identity(<Row>item)]
    );
  }

  doSort(sortCol: string, sortDir: string) {
    const column = this._columns.find((defination) => defination.column === sortCol);
    if (column === undefined) return;
    this.table.onColumnSort({ column: column, newValue: sortDir, sorts: [{ ...column, dir: sortDir }] });
  }

  onSort(event: SortEvent | any) {
    this.sort.next(event);
  }

  private _previousOffset = 0;

  onScroll({ offsetY }: { offsetY?: number } | any) {
    if (offsetY == undefined) return;
    if (offsetY <= this._previousOffset) {
      this._previousOffset = offsetY;
      return;
    }
    if (this.loading) return;
    const viewHeight = this.element.nativeElement.getBoundingClientRect().height - this.headerHeight;
    if (offsetY + viewHeight < this.rows.length * this.rowHeight) return;
    this._previousOffset = offsetY;
    this.scroll.next();
  }

  resetScrolls() {
    this.table.headerComponent.offsetX = 0;
    this.table.bodyComponent.offsetX = 0;
    this.table.bodyComponent.offsetY = 0;
    this._previousOffset = 0;
  }

  private initializeGroupForExpansion(group: Group<LineItem>) {
    if (this.groupsInitializedForExpansion.includes(group.id)) return EMPTY;
    const subject = new Subject<void>();
    this.groupsInitializedForExpansion = this.groupsInitializedForExpansion.concat(group.id);
    group
      .fetchRows()
      .pipe(tap((rows) => (group.rows = rows)))
      .subscribe(() => subject.complete());
    return subject.asObservable();
  }

  indeterminate(item: LineItem) {
    if (item.type !== "group") return false;
    const group = item as Group<LineItem>;
    return (
      group.rowIdentities.some((id) => !this.selectedIds.includes(id)) &&
      group.rowIdentities.some((id) => this.selectedIds.includes(id))
    );
  }

  private updateBodyHeight() {
    /**
     * Height of 1 Row = 36px. Set Max height of table to 10 Rows + 13px for scroll bar
     * */
    let height = this._rows.length * 36 + 13;
    if (height > 336) {
      height = 336 + 13;
    }
    (<HTMLElement>this.table.element.getElementsByClassName("datatable-body")[0]).style.minHeight = height + "px";
    (<HTMLElement>this.table.element.getElementsByClassName("datatable-body")[0]).style.height = height + "px";
  }

  private expandGroup(group: Group<LineItem>) {
    this.initializeGroupForExpansion(group).subscribe(
      () => {},
      () => {},
      () => {
        const index = this.rows.findIndex((item) => item.type === "group" && (<Group<LineItem>>item).id === group.id);
        const prefix = this.rows.filter((_, i) => i <= index);
        const suffix = this.rows.filter((_, i) => i > index);
        this.setRows(prefix.concat(group.rows, suffix));
        group.expanded = true;
        if (this.expandSubGroup) {
          group.rows.forEach((group: Group<LineItem>) => {
            if (group.type === "group") {
              this.expandGroup(group);
            }
          });
        }
      }
    );
    if (this.expandSubGroup) this.updateBodyHeight();
  }

  private collapseGroup(group: Group<LineItem>) {
    const index = this.rows.findIndex((item) => item.type === "group" && (<Group<LineItem>>item).id === group.id);
    group.rows
      .filter((item) => item.type === "group")
      .map((item) => <Group<LineItem>>item)
      .filter((group) => group.expanded)
      .forEach((group) => this.collapseGroup(group));
    const prefix = this.rows.filter((_, i) => i <= index);
    const limit = index + group.rows.length;
    const suffix = this.rows.filter((_, i) => i > limit);
    this.setRows(prefix.concat(suffix));
    group.expanded = false;
    if (this.expandSubGroup) this.updateBodyHeight();
  }

  toggleGroup(group: Group<LineItem>, expanded: boolean) {
    if (!expanded) {
      this.expandGroup(group);
      if (this.expandRefreshGroup && group.id.search(";") === -1 && !this.refreshGroupNames.includes(group.id)) {
        this.refreshGroupNames.push(group.id);
      }
    } else {
      this.collapseGroup(group);
      if (this.expandRefreshGroup && group.id.search(";") === -1) {
        this.refreshGroupNames = this.refreshGroupNames.filter((name) => name !== group.id);
      }
    }
  }

  resetGroupState(): void {
    this.refreshGroupNames.forEach((name) => {
      this.rows.forEach((group: Group<LineItem>) => {
        if (group.id === name) {
          this.toggleGroup(group, false);
        }
      });
    });
  }

  reset() {
    this.table.sorts = [];
  }

  updateRow(idField, id, field, value) {
    this._rows.forEach((row) => {
      if (row[idField] == id) {
        row[field] = value;
      }
    });
  }

  onSelectAllChanged(event) {
    this.isAllSelected = event.checked;
    this.isAllSelectedEvent.emit(this.isAllSelected);
  }

  checkEdge(event) {
    this.edge = event;
  }

  onMoving(event) {
    this.movingOffset.x = event.x;
    this.movingOffset.y = event.y;
  }

  onMoveEnd(event) {
    this.endOffset.x = event.x;
    this.endOffset.y = event.y;
    const frozenColsWidth = this.datatable.element.nativeElement.firstChild.clientWidth - event.x - 30;
    const frozenColWidth = frozenColsWidth / this.frozenRightColsLen;
    const cols = this._columns.map((col) => {
      return {
        column: col.column,
        draggable: col.draggable,
        frozenRight: col.frozenRight,
        name: col.name,
        prop: col.prop,
        sortable: col.sortable,
        width: col.frozenRight ? frozenColWidth : col.width,
        cellTemplate: col.cellTemplate,
      };
    });
    const newCols = cols.slice(0, this.outerColumns.length - 1);
    this.initColumns(newCols, true);
  }

  ngOnDestroy() {
    this.dragulaService.destroy(this.bag);
  }

  onFooterPage(event) {
    console.log(event);
  }

  onShowContainer(item) {
    console.log(item);
    if (item.rows[0]["Container Name"]) {
      this.containerPopUp.emit(item);
    }
  }
}
