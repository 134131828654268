import { ConfigurationService } from "src/app/core/svc/configuration-service";

const environment = new ConfigurationService().getConfig();

import { range } from "underscore";

export const INDEX_OF_MONDAY_IN_WEEK = 1;
export const DATE_LOCALE_STRING = "en-GB";
export const DD_MM_YYYY_Format = {
  parse: {
    dateInput: { month: "short", year: "numeric", day: "numeric" },
  },
  display: {
    dateInput: "input",
    monthYearLabel: { year: "numeric", month: "numeric" },
    dateA11yLabel: { year: "numeric", month: "long", day: "numeric" },
    monthYearA11yLabel: { year: "numeric", month: "long" },
  },
};

export const tradingModeFlags = {
  Auto: "A",
  Manual: "M",
  MediaOwnerPortal: "MOP",
};

const CURRENCIES = {
  UK: "GBP",
  US: "USD",
  CA: "CAD",
};

export const DEFAULT_CURRENCY = CURRENCIES[environment.region];
export const DEFAULT_WIDTH = "400px";

export const MONTH_MAPPING = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const MAP_VIEW_FRAME_SEARCH_WITH_IMPACTS_PAGE_SIZE = 2000;

export const MAP_VIEW_FRAME_SEARCH_PAGE_SIZE = 10000;

export const EXCLUDED_COLUMNS_FROM_SEARCH_RESULT = environment.excludeColumnsInSearchResult;

export const UAT_NOTICE = "uat-notice";

export const MAX_FRAMES_COUNT_IN_EXPORT_API_PAYLOAD = 10000;

export const MAX_FRAMES_COUNT_IN_MAP_VIEW = 200000;

export const POI_INCLUSION_THRESHOLD_VALUE = 60000;

export const POI_EXCLUSION_THRESHOLD_VALUE = 3000;

export const MEDIAOWNER_OPTIONING_MODE = environment.optioningMode;

export const CONTAINERS_ENABLED = environment.containersEnabled;

export const CONTAINER_GRID_ENABLED = environment.showContainerGrid;

export const MEDIA_OWNER_PORTAL_TRADING_MODE = "MediaOwnerPortal";

export const MANUAL_TRADING_MODE = "Manual";

export const AUTO_TRAINDING_MODE = "Auto";

export const APP_NOTIFICATION_CLICKED = "app-notification-clicked";

export const NOTIFICATION_TYPE = {
  CAMPAIGN_UPDATE: "CAMPAIGN_UPDATE",
};

export const AUTOSELECT_ALLOWED_FRAME_COUNT = 5000;

export const frameType = {
  FRAMEBUY: "FRAMEBUY",
  PACK: "PACK",
  CONTAINER: "CONTAINER",
  FREE_FORM_CONTAINER: "FREE_FORM_CONTAINER",
  NETWORK: "NETWORK",
};

export const DEFAULT_DAYPART_DETAILS_FOR_DIGITAL = {
  dayOfTheWeekDetails: [
    { dayOfTheWeek: 1 },
    { dayOfTheWeek: 2 },
    { dayOfTheWeek: 3 },
    { dayOfTheWeek: 4 },
    { dayOfTheWeek: 5 },
    { dayOfTheWeek: 6 },
    { dayOfTheWeek: 7 },
  ],
  dayPartDesc: "Network",
  timeRange: {
    fromTime: "00:00:00",
    toTime: "23:59:00",
  },
};

export const ENTERTAINER_DAYPART_DETAILS_FOR_DIGITAL = {
  dayOfTheWeekDetails: [
    { dayOfTheWeek: 1 },
    { dayOfTheWeek: 2 },
    { dayOfTheWeek: 3 },
    { dayOfTheWeek: 4 },
    { dayOfTheWeek: 5 },
    { dayOfTheWeek: 6 },
    { dayOfTheWeek: 7 },
  ],
  dayPartDesc: "Entertainer",
  timeRange: {
    fromTime: "20:00:00",
    toTime: "23:59:00",
  },
};

export const CAMPAIGN_ACTIVE_COLUMNS_UK = [
  "actions",
  "buyCost",
  "customerReference",
  "mediaType",
  "environment",
  "formatName",
  "mediaOwnerName",
  "mediaOwnerPrice",
  "name",
  "plannedCost",
  "groupCount",
  "bookingStatus",
  "schedule",
  "sellCost",
  "days",
];

export const CAMPAIGN_ACTIVE_COLUMNS_US = [
  "actions",
  "buyCost",
  "dates",
  "customerReference",
  "dma",
  "environment",
  "fixedCosts",
  "formatName",
  "frameId",
  "mediaOwnerName",
  "mediaType",
  "mediaOwnerFrameReference",
  "name",
  "installationCost",
  "productionCost",
  "groupCount",
  "bookingStatus",
  "sellCost",
];

export const CONVERSION_FACTORS_TO_METERS: object = {
  Miles: 1609.34,
  Kilometers: 1000,
  Meters: 1,
  Feet: 0.3048,
  Yards: 0.9144,
};

export const DISTANCE_PRECISION_METERS = 2;

export const SOT_PRECISION = 2;

export const DEFAULT_SPOT_LENGTH = 10;

export const DEFAULT_LOOP_LENGTH = 60;

export const DEFAULT_REACH = "National";

export const DEFAULT_BILLING_UNIT = "Same as the Media Owner";

export const DEFAULT_BUY_TYPE = "Broadcast";

export const DEFAULT_CYCLE_TYPE = "4-week";

export const DEFAULT_RATE_TYPE = "GRP";

export const PLEXUS_AGENCY_COMMISSION_LIST: number[] = range(25, -0.5, -0.5);
export const DEFAULT_PLEXUS_AGENCY_COMMISSION = 15;
export const PLEXUS_SAC_VALUE_LIST: number[] = range(0, 25.5, 0.5);
export const DOMESTIC_ROLES = ["ROLE_CAMPAIGN_PLANNER", "ROLE_INVESTMENT"];
export const INTERNATIONAL_ROLES = ["ROLE_PLEXUS_PLANNER", "ROLE_PLEXUS_INVESTOR"];

export const MO_CREATIVE_SOLUTIONS = "Talon Grand Visual";
export const CREATIVE_SOLUTIONS_COST = "creativeSolutionsCost";
export const BARTER_VALUE_HEADER_COST_CODE = "barter";

export const EXCLUDE_FRAMES_NEAR_SCHOOL_DISTANCE_LIST: Array<number> = [100, 200];

export const UPSITES_MARK_AS_SUCCESS_CONFIRMATION = "Do you want to change the status of line as Success?";
export const UPSITES_REVERT_TO_FAILED_CONFIRMATION = "Do you want to revert the status of line to Failed?";
export const UPSITES_STATUS_UPDATE_ERROR = "During the upsites status update, an error occurred.";
