import { Component, ContentChild, Input, OnInit, ViewChild } from "@angular/core";
import { MatFormField, MatFormFieldControl } from "@angular/material/form-field";

@Component({
  selector: "app-input",
  templateUrl: "./input.component.html",
  styleUrls: ["./input.component.scss"],
})
export class InputComponent implements OnInit {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ContentChild("ngContent", { static: true }) _control: MatFormFieldControl<any>;
  @ViewChild("testForm", { static: true }) _matFormField: MatFormField;
  @Input() label: boolean;
  @Input() required: boolean;

  ngOnInit() {
    this._matFormField._control = this._control;
  }
}
