import {
  addFrameDialogComfirmation,
  AvgImpression,
  CampaignActiveColumns,
  CampaignBudgetDetailsFields,
  CampaignGridDropDownOptions,
  captions,
  enableProhibition,
  enableSearchBySpotId,
  filterCaptions,
  Formats,
  frameColorCoding,
  frameLabelOnNavbar,
  framesLabelName,
  GetAgencyOuterColumnsV3,
  GetInnerColumnsV3,
  GetOuterColumnsV3,
  isGroupingConfigV3,
  isGroupingConfigV3US,
  isTradingTypeCPTByDefault,
  loadDatesInFormatMenu,
  LongLat,
  mapViewControl,
  panelListOption,
  platoMapLibcaptions,
  setFunctionsOfSpotIdBasedOnRegion,
  ShareListingPageCaptions,
  supportsPrintMedia,
  showRateType,
  showCycleType,
  showReferences,
  countryDropDown,
} from "./captions";
import { OptioningControlsConfiguration } from "src/app/core/models/domain/OptioningControlsConfiguration";
import {
  AATConfig,
  CampaignDetailsConfig,
  CampaignGridActiveColumns,
  GridConfig,
  HeaderConfig,
  LeftSideBarConfig,
  MapConfig,
  Region,
  ResultsViewConfig,
  SearchGridActiveColumns,
} from "src/app/core/models/domain/RegionFeaturesConfig";
import * as cols from "./columns";
import { campaignConfig } from "./campaign-config";

export const config = {
  region: Region.US,
  locale: "en_US",
  mapBoxCountryId: "US",
  mapBoxLongLat: [-96.6930257, 38.8951],
  mapDMALabelValue: captions.tv_region,
  mapDefaultZoom: 4,
  sourceSystems: ["Broadsign", "COMMB", "Geopath", "Media Owner", "Place Exchange"],
  defaultSourceSystems: ["Geopath", "Media Owner"],
  defaultCountries: ["US"],
  dateFormat: "MM/DD/YYYY",
  impactBaseValue: 1,
  defaultDistanceUnit: "Miles",
  defaultDistanceValue: 1,
  enableClientDashboardControls: true,
  autoSelectEnabled: false,
  reportsEnabled: true,
  packsEnabled: true,
  digitalPacksEnabled: true,
  notificationsEnabled: false,
  excludeColumnsInSearchResult: [],
  ffcFormConfigurations: {
    showAllMediaOwners: true,
    defaultReachValue: "DMA",
    showTargetImpressions: true,
    showDefaultImpressions: true,
  },
  playoutReporting: null,
  mediaOwnerServiceRequestsEnabled: true,
  containersEnabled: true,
  showContainerGrid: true,
  optioningControlsConfiguration: {
    prepareForOptioning: [],
    optioning: [],
    booking: [],
    cancellation: [],
  } as OptioningControlsConfiguration,
  leftSideBarConfig: {
    format: true,
    frameType: true,
    formatGroup: true,
    formatFormat: true,
    environment: true,
    availabilityDate: true,
    daypart: true,
    availability: false,
    frameOwner: true,
    directions: true,
    frames: true,
    frameIds: true,
    frameOthers: true,
    frameExclude: false,
    audience: true,
    geography: true,
    country: true,
    tvRegion: false,
    town: false,
    conurbation: false,
    cbsa: true,
    city: true,
    geoPostalCode: false,
    geoPostalArea: false,
    marketArea: true,
    state: true,
    county: true,
    zipcode: true,
    poiAndProximity: true,
    poiPostalCode: false,
    poi: true,
    poiCategory: true,
    poiBrand: true,
    poiBusiness: true,
    poiDistance: true,
  } as LeftSideBarConfig,

  headerConfig: {
    freeTextSearch: false,
    inAppNotification: false,
  } as HeaderConfig,

  resultViewConfig: {
    kpiDetails: false,
  } as ResultsViewConfig,

  campaignDetailsConfig: {
    kpiSummary: false,
    containerGrid: false,
  } as CampaignDetailsConfig,
  gridConfig: {
    captions: captions,
    filterCaptions: filterCaptions,
    mapCaptions: platoMapLibcaptions,
    columns: cols.columns,
    searchGridColumns: cols.searchGridColumns,
    searchGridActiveColumnsPreference: SearchGridActiveColumns.US,
    campaignGridActiveColumns: CampaignGridActiveColumns.US,
    columnsWidth: cols.columnsWidth,
    columnsSortable: cols.columnsSortable,
    columnsDraggable: cols.columnsDraggable,
    mandatoryColumns: cols.mandatoryColumns,
    userSelectedColumns: cols.userSelectedColumns,
    pinnedRightColumns: cols.pinnedRightColumns,
    features: cols.features,
    featuresEnabled: cols.featuresEnabled,
  } as GridConfig,
  aatConfig: {
    isRouteVersionSelectionMandatory: false,
    isFileUploadEnabled: false,
    analyticalSystem: "Geopath",
    columns: cols.aatColumns,
    isAddAudienceEnabled: false,
    aatEntryUrl: "/audience-analysis-tool/compare",
    isGeoTargetingEnabled: true,
  } as AATConfig,
  mapConfig: {
    defaultZoom: 4,
    shouldUpdatePrice: true,
    showPrice: true,
    listenPOIRenderingStatus: true,
    updateDMAAndCountyBoundaries: true,
  } as MapConfig,
  campaignConfig: campaignConfig,
  Formats,
  CampaignBudgetDetailsFields,
  CampaignGridDropDownOptions,
  AvgImpression,
  CampaignActiveColumns,
  LongLat,
  GetOuterColumnsV3,
  GetInnerColumnsV3,
  GetAgencyOuterColumnsV3,
  ShareListingPageCaptions,
  supportsPrintMedia,
  frameColorCoding,
  framesLabelName,
  frameLabelOnNavbar,
  enableSearchBySpotId,
  panelListOption,
  loadDatesInFormatMenu,
  setFunctionsOfSpotIdBasedOnRegion,
  enableProhibition,
  mapViewControl,
  addFrameDialogComfirmation,
  isTradingTypeCPTByDefault,
  isGroupingConfigV3US,
  isGroupingConfigV3,
  showRateType,
  showCycleType,
  showReferences,
  countryDropDown,
};
