<div class="column-toggler">
  <div class="column-toggler-header">
    <h6>Select columns to view</h6>
  </div>
  <div class="column-toggler-content">
    <ul>
      <li *ngFor="let column of values">
        <mat-checkbox labelPosition="after" color="primary" [(ngModel)]="column.selected">
          {{ column.caption }}
        </mat-checkbox>
      </li>
    </ul>
  </div>
  <div class="column-toggler-footer">
    <button type="primary" (click)="onSaveClick($event)" data-cy="save-columns" class="primary-btn">Save</button>
    <button type="secondary" (click)="onResetClick($event)" class="secondary-btn">Reset</button>
  </div>
</div>
