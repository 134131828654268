import { Component, OnInit, Inject, EventEmitter, Output, Input } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-error-dialog",
  templateUrl: "./error-dialog.component.html",
  styleUrls: ["./error-dialog.component.scss"],
})
export class ErrorDialogComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<ErrorDialogComponent>) {}

  @Output() closed = new EventEmitter<boolean>();

  @Input() errorCategory: string = "Error";
  @Input() errorMessage: string;
  @Input() errorDetails: string;

  ngOnInit() {
    if (this.data) {
      this.errorCategory = this.data.errorCategory;
      this.errorMessage = this.data.errorMessage;
      this.errorDetails = this.data.errorDetails;
    }
  }

  close() {
    this.closed.emit(true);
    this.dialogRef.close();
  }
}
